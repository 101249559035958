<template>
  <div id="app">
    <div class="global-loding"></div>
    <router-view/>
  </div>
</template>
<script>
export default {
  name: 'App',
  created(){
		console.log(navigator);
    console.log(localStorage.getItem('language'))
      if(localStorage.getItem('language')){
        this.$i18n.locale=localStorage.getItem('language')
      }
　　　　
  }
}
</script>
<style lang="less">

</style>
