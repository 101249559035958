<template>
	<div>
	    <div class="scontlist">
	    <h3>社区列表</h3>
	    <el-row class="cont">
	        <el-col v-for="(item,index) in shelist" :key='index' :span="4" >
	            <div class="grid-content" @click="handlruls(item.id)">
	                <p>社区名称：{{item.name}}</p>
	                <p>设备数 ：{{item.device_number}}</p>
	                <p>用户数 ：{{item.user_number}}</p>
	                <p>创建时间：{{item.add_time}}</p>
					<p class="grid-content_item" @click.stop="clear">删除</p>
	            </div>
	        </el-col>
	    </el-row>
	    </div>
	</div>
</template>

<script>
	import {formatDate} from '@/utils/date'
	import methodlist from '@/utils/methods'
	export default{
		data(){
			return{
				shelist:[],
				UserInfo:''
			}
		},
		methods:{
			handlruls(e){
				console.log(e);
				const user = {
				  name: this.UserInfo.user_name_save, // 手机号
				  password: this.$md5(this.UserInfo.user_pass_save),
				  method: methodlist.ee_login,
				  agent_id:e
				}
				this.$serve(user).then(res => {
				  if(res.data.code == 0){
					  res.data.data.user_name_save= this.UserInfo.user_name_save;
					  res.data.data.user_pass_save = this.UserInfo.user_pass_save;
						window.localStorage.setItem('user', JSON.stringify(res.data.data))
						this.$router.push({ path: '/home' })
				  }else{
					  if(res.data.code == -3){
					      this.$message({
					        message: '用户名不存在，',
					        type: 'error'
					      });
					  }else if(res.data.code == -6){
					      this.$message({
					        message: '密码错误',
					        type: 'error'
					      });
					  }else{
					      this.$message({
					        message: '登录失败',
					        type: 'error'
					      });
					  }
				  }
				
					
				})
			},
			getshelist(){
				let data = {
					method:'co.agent.list',
					manager_id:this.UserInfo.manager_id
				}
				this.$serve(data).then(res=>{
					console.log(res);
					if(res.data.code==0){
						let data= res.data.data
						data.forEach(el => {
						    el.add_time = formatDate(new Date(el.add_time * 1000), 'yyyy-MM-dd hh:mm')
						});
						this.shelist= data
					}
				})
			}
		},
		mounted () {
		    this.UserInfo = JSON.parse(window.localStorage.getItem('user'));
			console.log(this.UserInfo);
			this.getshelist()
		}
	}
</script>

<style lang="less" scoped>
 .scontlist{
    width: 100%;
    height: auto;
    h3{
        margin-bottom: 25px;
        text-align: center;
    }
    .cont{
        
        .grid-content{
            border: 1px solid #ccc;
            height: 150px;
            margin-left: 20px;
            border-radius:10px;
            cursor: pointer;
            p{
                padding: 7px 0 0 7px;
            }
			.grid-content_item{
				color: red;
				width: 30%;
				height: 20px;
				line-height: 20px;
				text-align: center;
				border: 1px solid #000;
				padding:5px 0;
				margin-left: auto;
			}
        }
    }
    
}
</style>