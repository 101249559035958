export default{
    state: {
        langvalue:"",
    },
    // 改变state中变量的方法，mutations是改变state数据的唯一途径，commit()
    mutations: {
        selectree (state,value){
            state.langvalue = value
        },
    },
    mutations:{
        langhanvale(state,value){
            if(value){
                state.langvalue = value
            }
            console.log(`45`,state.langvalue)
        },
    },
    // 做逻辑操作，计算、发送ajax，dispath()
    actions: {
    },
}