export default{
    state: {
        menu:[],
        currentMenu:null,
        fathcurrentMenu:null,
    },
    getters:{
        //方法调用监听state改变
		getStateCurrentMenu:function(state){
			if (state.connt!=null) localStorage.setItem('currentMenu',state.currentMenu);
			return localStorage.getItem('currentMenu'); //数据进入缓存以便于刷新调用
		}
    },
    // 改变state中变量的方法，mutations是改变state数据的唯一途径，commit()
    mutations: {
        selectMenu(state,value){
            if(value){
                state.currentMenu = value
            }
            

            
        },
        faselectMenu(state,value){
            console.log(`55`,state)
            if(value){
                state.fathcurrentMenu = value
            }
            
            
        }
    },
    // 做逻辑操作，计算、发送ajax，dispath()
    actions: {
    },
}