<template>
  <el-container class="layout-container">
    <el-aside class="aside" width="auto">
      <AppAside :is-collapse="isCollapse" class="aside-menu" />
    </el-aside>
    <el-container>
        <el-header class="header">
          <div style="display:flex">
            <div class="handicon" @click="handisCollapse">
              <i :class='["incon",isCollapse ? " el-icon-s-unfold " : "el-icon-s-fold"]  '></i> 
              <!-- el-icon-s-unfold -->
            </div>
            <!-- 面包屑 -->
            <!-- <div style="margin-left:20px">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item>{{$route.meta.module}}</el-breadcrumb-item>
                  <el-breadcrumb-item>{{$route.meta.title}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div> -->
          </div>

          

          <el-dropdown trigger="click">
             <div class="avatar-wrap">
                <img class="avatar" @click="choiceImg" :src="head_path" alt="">
                <input id="imagePic" name="imagePic" @change="getFile" ref="filElem" class="butuploud" type="file" accept="image/jpg,image/jpeg" />
                <span>{{UserInfo.user_name_save}}</span>
                <i class="el-icon-arrow-down el-icon--right"></i>
              </div>
            <el-dropdown-menu slot="dropdown">
              <!-- <el-dropdown-item split-button="true" @click.native="languClic">中文/英文</el-dropdown-item> -->
              <el-dropdown-item
                @click.native="onLogout"
              >{{$t('header_meun.logout')}}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-header>
        <el-main class="main">
          <!-- <transition enter-active-class="animated fadeInLeftBig" leave-active-class="animated fadeInLeftBig"> -->
             <div class="content">
               <router-view />
             </div>
          <!-- </transition> -->
        </el-main>
    </el-container>
     <Vuecropperr ref="vuecrropperr" @finish="finish"></Vuecropperr>
  </el-container>
</template>
<script>
import AppAside from './components/aside'
import { mapState } from 'vuex'

import Vuecropperr from '@/views/visitormanagement/components/vuecropperr'
import methodlist from '../../utils/methods'
import jq from 'jquery'
var Base64 = require('js-base64').Base64;
export default {
  name: 'LayoutIndex',
  components: {
    AppAside,
    Vuecropperr
  },

  mounted () {
    this.UserInfo = JSON.parse(window.localStorage.getItem('user'))
    console.log(this.UserInfo)

    if(this.UserInfo.head_url.length != 0)
      this.head_path = methodlist.resident_pic_base + this.UserInfo.head_url +"?tempid="+Math.random()
    else
      this.head_path = require('@/assets/image/default_head.png') //'../../assets/image/default_head.png'     
  },
  data () {
    return {
      show: true,
      isCollapse: false, // 侧边菜单栏的展示状态
      head_url: '',
      head_path: '',
      UserInfo:JSON.parse(window.localStorage.getItem('user'))
      
    }
  },
  methods: {
    // languClic(){
    //   //  this.lang = localStorage.getItem('language') || 'cn';
    //    if ( this.lang === 'cn' ) {
    //       this.lang = 'en';
    //       this.$i18n.locale = this.lang;
    //    } else {
    //       this.lang = 'cn';
    //       this.$i18n.locale = this.lang;
    //    }
    //    this.$store.commit('langhanvale', this.lang)

    //   //  localStorage.setItem('language', this.lang);
    // },
    onLogout () {
      this.$confirm(this.$t('devtable.qrtcm'), this.$t('devtable.tcts'), {
        confirmButtonText: this.$t('devtable.qdd'),
        cancelButtonText: this.$t('devtable.qxx'),
        type: 'warning'
      }).then(() => {
        // 把用户的登录状态清除
        window.localStorage.removeItem('user')
        window.localStorage.removeItem('language')
        // 跳转到登录页面
        this.$router.push('/login')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消退出'
        })
      })
    },

    finish(e){
      //console.log(e)
      console.log('上传base64图片');
      this.deleteOldHeadImg(e);
    },
    // 图片选择上传
    choiceImg(){
      this.$refs.filElem.dispatchEvent(new MouseEvent('click')) 
    },
    getFile(e){
      var file = e.target.files[0];
      //console.log(file)
      var filesize = file.size;
      var filename = file.name;
      // 2,621,440   2M
      if (filesize > 2101440) {
        // 图片大于2MB
        console.log(11)
      }
      var reader = new FileReader();
      var _this=this
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        // 读取到的图片base64 数据编码 将此编码字符串传给后台即可
        // var imgcode = e.target.result;
        // _this.imgSrc = e.target.result
        _this.$refs.vuecrropperr.dialogVisible=true
        _this.$refs.vuecrropperr.option.img=e.target.result
        // console.log(e.target.result)
      }
    },    

    //第一步：删除旧的头像图片
    deleteOldHeadImg(imgData){
				
      let mqttInfo = this.UserInfo.mqtt_serial + ':' + this.UserInfo.mqtt_password;            
      //mqttInfo = '0AwAA:81905683';//测试用                

      this.head_url = methodlist.type_head + this.UserInfo.manager_id + ".jpeg";      
      //this.head_url = methodlist.type_face + this.UserInfo.manager_id + ".jpeg";
      var delImgPath=methodlist.upload_img_base + this.head_url;
      var data = '';
      var _this = this;
			jq.ajax({
				headers : {
				    'Authorization' : 'Basic '+Base64.encode(mqttInfo),
					'Content-MD5': data,
				},
				url : delImgPath, 
				type : 'DELETE',							
				contentType: "false",
				data : data,
				success : function(res) {
					console.log("删除头像OK",JSON.stringify(res));
					_this.uploadFaceImg(imgData);
				},
				error : function(res){
					console.log("删除头像Err",JSON.stringify(res));
					_this.uploadFaceImg(imgData);
				}
			});
    },
    //第二步：上传头像图片
    uploadFaceImg(imgData){
        //console.log(imgData)            
        let mqttInfo = this.UserInfo.mqtt_serial + ':' + this.UserInfo.mqtt_password;
        //mqttInfo = '0AwAA:81905683';//测试用
        let up_url_Path = methodlist.upload_img_base + this.head_url;
        var data = 'temporary=true&imgBase64='+imgData.substring('data:image/jpeg;base64,'.length);
        var _this = this;
        jq.ajax({
          headers : {
            'Authorization' : 'Basic '+Base64.encode(mqttInfo),
            'Content-MD5': this.$md5(data)
          },
          url: up_url_Path,	 
          type : 'POST',
          contentType: "application/x-www-form-urlencoded",
          data : data,
          success : function(res) {	
            console.log(res);                    
            _this.updateHeadImgUrl();
          },
          error : function(res){
              console.log(res);						
          }
        });

    },
    //第三步：更新服务器路径
    updateHeadImgUrl(){
        const params = {
            method: methodlist.ee_profile_update,
            manager_id: this.UserInfo.manager_id,
            head_url: this.head_url
        }        
        this.$serve(params).then(res => {
            console.log(res);
            if(res.data.code == 0){
                this.$message({
                    type: 'success',
                    message: '头像更新成功!'
                })
                 this.UserInfo.head_url = this.head_url
                this.head_path = methodlist.resident_pic_base + this.head_url +"?tempid="+Math.random()
            }else{
                this.$message({
                  type: 'warning',
                  message: '头像更新失败!'
                })
            }
        });      
    },
    imgHexNameFromId(id){
            //this.faceimg.person_id = 1000;		
			let num = parseInt(id).toString(16);
			let id8Hex = ('00000000'+num).slice(-8);
			let id8HexUpName = id8Hex.toUpperCase();
			return id8HexUpName;
    },
    // 折叠
    handisCollapse(){
        this.isCollapse = !this.isCollapse
    }
  }
}
</script>
<style lang="less" scoped>
.layout-container{
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.aside {
  &::-webkit-scrollbar
      {
        width: 6px;
        height: 6px;
        background-color: #F5F5F5;
      }
      
      /*定义滚动条轨道 内阴影+圆角*/
      &::-webkit-scrollbar-track-piece
      {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
        border-radius: 6px;
        background-color: #F5F5F5;
      }
      
    /*定义滑块 内阴影+圆角*/
    &::-webkit-scrollbar-thumb
    {
      border-radius: 5px;
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #555;
    }
  .aside-menu {
    height: 100%;
     
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  .handicon{
    font-size: 28px;
    margin-left: 5px;
    
    .incon{
      font-weight: 100;
      color: #333;
    }
  }
  .avatar-wrap {
    display: flex;
    align-items: center;
    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 10px;
    }
    .butuploud{
      margin: 0;
      padding: 0;
      width:100%;
      height:100%;
      z-index:999999;
      position: absolute;
      left: 0;
      top: 0;
      display: none;
    }
  }
  
}
.main{
  background-color: #ffffff;
  .content{
    box-shadow: 0 2px 4px 0 rgb(220 220 220),  0 0 6px 0 rgb(220 220 220);
    border: 1px solid rgb(220, 223, 230);
  }
  
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.el-breadcrumb{
  line-height: 32px;
}

.layout-container /deep/.el-menu{
  border-right:none
}
.layout-container /deep/.el-main{
  // padding: 5px 0px;
}
.layout-container /deep/ .el-menu-vertical-demo{
  height: 100%;
}
</style>
