<template>
    <div>
         <el-dialog :title="$t('devtable.tpcj')" :visible.sync="dialogVisible" append-to-body>
            <div class="cropper-content">
                <div class="cropper" style="text-align:center">
                 <vue-cropper
        ref="cropper"
        :img="option.img"
        :output-size="option.size"
        :output-type="option.outputType"
        :info="true"
        :full="option.full"
        :fixed="option.fixed"
        :fixed-number="option.fixedNumber"
        :can-move="option.canMove"
        :can-move-box="option.canMoveBox"
        :fixed-box="option.fixedBox"
        :original="option.original"
        :auto-crop="option.autoCrop"
        :auto-crop-width="option.autoCropWidth"
        :auto-crop-height="option.autoCropHeight"
        :center-box="option.centerBox"
        :high="option.high"
        mode="cover">
      </vue-cropper>
                </div>
            </div>
            <div slot="footer" class="dialog-footer">
                <!-- <el-button @click="dialogVisible = false">取 消</el-button> -->
                <el-button type="primary" @click="finish">{{$t('tablename.qd')}}</el-button>
            </div>
            </el-dialog>
    </div>
</template>
<script>
export default {
    data(){
        return{
            dialogVisible:false,
            option: {
                img: '', // 裁剪图片的地址
                outputSize: 1, // 裁剪生成图片的质量
                outputType: 'jpeg', // 裁剪生成图片的格式
                full: false, // 是否输出原图比例的截图
                info: true, // 图片大小信息
                canScale: true, // 图片是否允许滚轮缩放
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 200, // 默认生成截图框宽度
                autoCropHeight: 250, // 默认生成截图框高度
                // 截图框的宽高比例
                fixedNumber: [1, 1.25],
                // 固定截图框大小 不允许改变
                fixedBox: true,
                canMove: true, // 上传图片是否可以移动
                fixedBox: false, // 固定截图框大小 不允许改变
                fixed: true, // 是否开启截图框宽高固定比例
                canMoveBox: true, // 截图框能否拖动
                original: true, // 上传图片按照原始比例渲染
                centerBox: true, // 截图框是否被限制在图片里面
                height: true,
                infoTrue: false, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
                enlarge: 1, // 图片根据截图框输出比例倍数
                mode: 'container', // 图片默认渲染方式
                high: true,
                maxImgSize: 640 // 限制图片最大宽度和高度
            },
        }
    },
    mounted(){
        console.log(this.option.img)
        console.log(`99`,this.autoCropWidth)
    },
    methods:{
        finish(){
            this.$refs.cropper.getCropData((data) => {    
                console.log(`66`,data)        
                this.$emit('finish',data)
                this.dialogVisible=false
            });
        }
    }
}
</script>
<style lang="less" scoped>
.cropper-content {
    .cropper {
        width: auto;
        height: 350px;
    }
}
</style>