<template>
  <div class="contanaier_row">
    <div class="cont_cops">
      <div class="shortcut_row">
        <div class="short_bottom">{{$t('devtable.kjfs')}}</div>
        <div class="short_top">
            <el-row :gutter="20">
                
                <el-col :span="6" class="shor-cont">
                    <div>
                      <router-link to="/structure">
                        <icon-svg icon-class="u559" class="iii" />
                       </router-link>
                    </div>
                    <p>{{$t('navLeft.qyjg')}}</p>
                </el-col>
                
                <el-col :span="6" class="shor-cont">
                    <div>
                      <router-link to="/devicemanagem">
                        <icon-svg icon-class="u556" class="iii" />
                       </router-link>
                    </div>
                    <p>{{$t('navLeft.sbglH')}}</p>
                </el-col>
                <el-col :span="6" class="shor-cont">
                    <div>
                      <router-link to="/statusquery">
                        <icon-svg icon-class="u553" class="iii" />
                       </router-link>
                    </div>
                    <p>{{$t('navLeft.ztcx')}}</p>
                </el-col>
                <el-col :span="6" class="shor-cont">
                    <div>
                       <router-link to="/perinformation">
                        <icon-svg icon-class="zil" class="iii" />
                       </router-link>
                    </div>
                    <p>{{$t('navLeft.ryzl')}}</p>
                </el-col>
                
            </el-row>
            <el-row :gutter="20">
                <el-col :span="6" class="shor-cont">
                    <div>
                      <router-link to="/authmanagem">
                        <icon-svg icon-class="u571" class="iii" />
                       </router-link>
                    </div>
                    <p>{{$t('navLeft.qxgl')}}</p>
                </el-col>
                <el-col :span="6" class="shor-cont">
                    <div>
                      <router-link to="/synchro">
                        <icon-svg icon-class="u567" class="iii" />
                       </router-link>
                    </div>
                    <p>{{$t('navLeft.sjtb')}}</p>
                </el-col>
                <el-col :span="6" class="shor-cont">
                    <div>
                      <router-link to="/datasources">
                        <icon-svg icon-class="u563" class="iii" />
                       </router-link>
                    </div>
                    <p>{{$t('navLeft.kqgl')}}</p>
                </el-col>
                <el-col :span="6" class="shor-cont">
                    <div>
                      <router-link to="/perecord">
                        <icon-svg icon-class="u575" class="iii" />
                       </router-link>
                    </div>
                    <p>{{$t('navLeft.txjl')}}</p>
                </el-col>
            </el-row>
        </div>
      </div>
      <div class="shortcut_row" style="margin-left:15px;margin-right:15px">
        <div class="short_bottom">{{$t('devtable.dbsx')}}</div>
        <el-row class="diaban" >
          <el-col :span="11" class="back">
             <router-link to="/selfaudit">
              <p>{{$t('navLeft.zzsh')}}</p>
             </router-link>
              <!-- <span>0</span> -->
          </el-col>
          <el-col :span="11" :offset="2" class="back">
             <router-link to="/appointment">
              <p>{{$t('navLeft.yysh')}}</p>
             </router-link>
              <!-- <span>0</span> -->
          </el-col>
        </el-row>
        <el-row class="diaban" >
          <el-col :span="11"  class="back">
              <p>{{$t('devtable.dshry')}}</p>
              <span>0</span>
          </el-col>
          <el-col :span="11" :offset="2" class="back">
              <p>{{$t('devtable.dshry')}}</p>
              <span>0</span>
          </el-col>
        </el-row>
      </div>
      <div class="shortcut_row" v-if="this.$i18n.locale == 'cn'">
        <div class="short_bottom">{{$t('devtable.glxtczsp')}}</div>
        <div >
          <iframe frameborder="0" style="height: 250px;
    width: 100%;" src="https://v.qq.com/txp/iframe/player.html?vid=q3245nwhs17" allowFullScreen="true"></iframe>
        </div>
      </div>
    </div>
    <div class="cont_tops">
        <el-row class="touhe">
          {{$t('devtable.sjgll')}}
          <el-select size="small" v-model="value" :placeholder="$t('devtable.qxz')">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-row>
        <el-row>
          <el-col :span="12"><ve-pie :data="chartData"></ve-pie></el-col>
          <el-col v-if="lang=='en'" :span="12"><ve-line :data="chartData2" ></ve-line></el-col>
          <el-col v-else :span="12"><ve-line :data="chartData" ></ve-line></el-col>
          
        </el-row>
    </div>
    <!-- <ve-pie :data="chartData" :settings="chartSettings"></ve-pie> -->
  </div>
</template>
<script>
import '@/icons/ww.svg' 
import '@/icons/zil.svg' 
import '@/icons/u559.svg' 
import '@/icons/u556.svg' 
import '@/icons/u571.svg' 
import '@/icons/u567.svg' 
import '@/icons/u563.svg' 
import '@/icons/u575.svg' 
import '@/icons/u553.svg' 
export default {
  data () {
    return {
      lang:window.localStorage.getItem('language'),
      chartData: {
          columns: ['date', '访问用户'],//
          rows: [
            { 'date': '1/1', '访问用户': 1393 },
            { 'date': '1/2', '访问用户': 3530 },
            { 'date': '1/3', '访问用户': 2923 },
            { 'date': '1/4', '访问用户': 1723 },
            { 'date': '1/5', '访问用户': 3792 },
            { 'date': '1/6', '访问用户': 4593 }
          ]
        },
         chartData2: {
          columns: ['日期', 'Access user date'],//
          rows: [
            { '日期': '1/1', 'Access user date': 1393 },
            { '日期': '1/2', 'Access user date': 3530 },
            { '日期': '1/3', 'Access user date': 2923 },
            { '日期': '1/4', 'Access user date': 1723 },
            { '日期': '1/5', 'Access user date': 3792 },
            { '日期': '1/6', 'Access user date': 4593 }
          ]
        },
        
      options: [
        // {
        //   value: '选项1',
        //   label: '黄金糕'
        // }, {
        //   value: '选项2',
        //   label: '双皮奶'
        // }, {
        //   value: '选项3',
        //   label: '蚵仔煎'
        // }, {
        //   value: '选项4',
        //   label: '龙须面'
        // }, {
        //   value: '选项5',
        //   label: '北京烤鸭'
        // }
        ],
        value: ''
      
    }
  },
  created(){
     if(localStorage.getItem('language')){
        this.$i18n.locale=localStorage.getItem('language')
      }
  }
}
</script>
<style lang="less" scoped>
.contanaier_row{
  background-color: #fff;
  padding: 20px;
  .cont_cops{
    display: flex;
    .shortcut_row{
      flex: 3;
      width: 100%;
      height: 300px;
      border: 1px solid #cccccc;
      .short_bottom{
        height: 50px;
        border-bottom: 1px solid #cccccc;
        line-height: 50px;
        padding-left: 20px;
        
      }
      .short_top{
        padding: 20px;
        .shor-cont{
          font-size: 14px;
          text-align: center;
          p{
            margin-top: 5px;
          }
          div{
            width: 100%;
            height: 80px;
            background-color: rgba(242, 242, 242, 1);
            border-radius: 5px;
          }
        }
      }
    }
    .todolist_row{
      flex: 3;
      width: 100%;
      height: 260px;
      border: 1px solid #cccccc;
      margin: 0 40px;
    }
    .white_row{
      flex: 3;
      width: 100%;
      height: 260px;
      border: 1px solid #cccccc;
    }
  }
  .cont_tops{
    height: 400px;
    margin-top: 20px;
    border: 1px solid #ccc;
    .touhe{
      border-bottom: 1px solid #ccc;
      height: 50px;
      line-height: 50px;
      padding-left: 30px;
    }
  }

}
.iii{
  width: 40px;
  height: 40px;
  margin-top: 20px;
}
.diaban{
  margin-top:20px;
  padding:  0 20px;
    .back{
      background-color: rgba(242, 242, 242, 1);
      height: 90px;
      text-align: center;
      cursor: pointer;
      p{
        margin-top: 20px;
        margin-bottom: 8px;
      }
      span{
        font-size: 18px;
        font-weight: 600;
        color: #02A7F0;
      }
    }
  }
</style>
