module.exports={
    // 头部菜单
    header_meun:{
        logout: "log out"
    },
    // 左侧导航
    adminhou:'management system',
    adminname:'System',
    navLeft:{
        qygl:"Company",
        qyzl:"About Company",
        qyjg:'Company structure',
        add:'Add',
		addR:'Add rule',
		addU:'Add User',
        sbgl:'Devices',
		sbglH:'Device control',
        ztcx:'Status enquery',
        yggl:'Staff',
		ygglH:'Staffs',
        ryzl:'Staff directory',
		ryzlH:'Staff directory',
        zzsh:"Staff audit",
        qxgl:'Authority management',
        sjgl:'Time management',
        fkgl:'Visitors',
        fkdj:'Visitor registration',
        yysh:'Appointments',
        kqgl:'Attendance MGMT',
        sjly:'Data sources',
        kqcs:'Attendance parameters',
		kqsz:'Attendance settings',
        kqbc:'Attendance shift',
        kqpb:'Attendance schedules',
        jrgl:'Holiday schedule',
        qjgl:'Exceptions control',
        sgqd:'Manual Check-in',
        kqcl:'Time tracking',
        bbsj:'Reports',
        txjl:'Event list',
        kqrb:'Daily attendance report',
        kqyb:'Monthly attendance report',
        fkjl:"Visitors events",
        ffjl:'Illegal events',
        zpjl:'Camera records',
        xtsz:'System settings',
        glysz:'Administrator settings',
        llksz:'Flow card query',
        hkbk:'Replacement of bad card',
        qdgl:'Sign in management',
        sjtb:'Data synchronization',
		zncw:'Intelligent parking space',
		sscw:"Real time parking space",
		cwgl:"Parking space management",
		ccxx:"Parking lot information",
		tjkqcl:'Add time tracking ',
    },
	LaterOhther:{
		bjjlcl:'Alarm record processing',
		znIPC:'Intelligent IPC',
		bjcljl:'Alarm processing record',
		szbjts:'Set alarm push',
		tsbjjl:'Push alarm record',
		ssspck:'Real time video viewing',
		spckjl:'Video viewing record'
	},
    tablename:{
        scewm:'Generate QR code',
        xgqyzl:'Modify enterprise information',
		xgqyzlH:'About company',
        gsmc:'corporate name',
		gsmcH:'Company name',
        lxdh:'Contact number',
        yxdz:'E-mail',
        szsf:'Province',
        szcs:'City',
        xxdz:'Address',
        qd:'Submit',
        qymc:'Compony',
        cz:'Operation',
        zj:'Add',
        sc:'Delete',
        bj:'Edit',
		bjH:'Edit',
		jsj:'Add Time',
		sjqh:'Mobile area code',
    },
    devtable:{
		qrsqsz:'Confirm Settings',
		zdsqglan:'Automatic authorization management button',
		zdsqjzsj:'Automatic authorization deadline',
		zdsqkl:'Automatic authorization password',
		qsr:'Please enter',
		rq:'Date',
        rqgs:'Date format',
        moth:'month',
        data:'day',
        shb:'Equipment number',
        sbbzx:'Device not online',
        zzmm:'Reset password',
        zzcg:'Reset successful',
        zzsb:'Reset failed',
        qc:'Eliminate',
        gx:'Update',
        km:'Open',
        wh:'Maintain',
        xg:'Modify',
        cq:'Restart',
		llk:'Flow card',

        sb:'Device',
		sbH:'Devices',
        azwz:'Location',
        kmzt:'Pass direction',
        kmms:'Open mode',
		kmmsI:'Identifier type',
        sbxlh:'Device S/N',
        zxzt:'Online status',
        sblx:'Device type',
        zcj:'Register device',
		sbh:'dev',

        ysms:'Delay mode',
        ckms:'Normally open',
        cbms:'Normally closed',
        frlj:'NO face machine',
        rlj:'face machine',
        zx:'on-line',
        lx:'off-line',
        s:'Yes',
        f:'No',
        j:'Entry',
        c:'Exit ',

        sbmc:'Device name',
		sbxlhD:'Device number',
        sbzt:'Device status',
        wxz:'Not selected',
        cx:'Query',

        rlsl:'Number faces',
        mksl:'Number cards',

        zcid:'Registration ID',
        xm:'Name',
        gh:'Job number',
		ghHH:'Staff number',
		ghH:'Job phone',
        qybm:'Department',
        xb:'Gender',
        sjhm:'Phone',
        zjhm:'Personnel Number',
		zjhmH:'Document number',
        kh:'Card number',
        rxzp:'Photo',
        yl:'Preview',
        male:'male',
        female:'female',

        shzt:'Audit status',
        rzlx:'Authentication type',
        shr:'Reviewer',
        shsj:'Audit time',
        zczt:"Registration status",
        ysp:'Approved',
        wsp:'Not approved',
        wzc:'Unregistered',
		qbmr:'All(default)',
        yzc:'Registered',
        bfzc:'Partial registration',
        zcsb:'Login has failed',
        yg:'Staff',
        yl:'Preview',
        bm:'Department',
        sh:'Сheck',
		sjhmN:'Phone number',
        plsh:'Batch audit',
        qbsh:'Full audit',

        rylx:'Personnel type',
		rylxS:'Staff type',
        tbsj:'Synchronization time',
        tbzt:'Synchronization status',
        qxck:'Permission view',
        fk:'Visitor',
        wtb:'Not synchronized',
        ry:'Personnel',
        lwtb:'Face not synchronized',
        kwtb:'Card not synchronized',
        kmmmwtb:'Door opening password not synchronized',
        ytb:"Synchronized",
        bzx:'Not online',
        ymj:'Cloud Gate ban',

        sdh:'Period number',
        qsrq:'Start date',
        jzrq:'End date',
        sjms:'Time description',
        xygljsd:'Next link period',
        cj:'Establish',
        qdjcx:'Please click query',

        lfrxm:'Visitor name',
        bfrbm:'Responsible dept',
		bfrbmT:'Responsible department',
        bfr:'Responsible person',
		bfrr:'Responsible staff',
        bfrsjhm:'Resp. person phone',
        lfrzp:'Visitors photos',
		lfrzpD:'Visitor photo',
        lfsj:'Visiting time',
        lfcph:"Visiting vehicle number",
        tb:'Synchro',
        tjsbkq:'Add attendance devices',
		tjsbkqH:'Add attendance device',
        mc:'Name',
        dklx:'The way you clock in',
        sbsjd:'Working hours',

        gzmc:'Rule name',
		gzmcH:'Day shift night shift',
        bcjgz:"Frequency and rules",
        yyfw:'Scope of application',
        xh:'Serial number',

        jjrmc:'Name',
        ksrq:'Start date',
        xzrq:"Select date",
        jsrq:'End date',
		ksrqH:'Start date',
		jsrqH:'End date',

        ms:'Description',
        qjlx:'Exeption type',
		qjlxH:'Exception type',

        ygid:'Staff ID',
        ygxm:"Staff name",
        qdsj:'Check in time',
        bz:'Remark',
        bc:'work shifts',

        tgsj:'Event time',
        zt:'State',
        kmlx:'Door opening type',

        skrq:'Event date',
        cdfz:"Late (min)",
        ztfz:'Early leave (min)',
        jbfz:"Overtime (min)",
        sk:'Swipe card ',
        yf:'Month',
        yqts:'Attendance days',
        sqts:'Actual attendance',
        cdcs:'Late (min)',
        ztcs:'Early leave (min)',
        yskcs:'Card should be swiped (times)',
        wskcs:'No card swiping(times)',
        qjt:'Leave(days)',
        cct:'Business trip(days)',
        jbxs:'Overtime (min)',
        kgt:'Absenteism (days)',

        sfr:'Responsible visitor',
		kssjS:'Start time',
		jssjS:'End time',
        sfrsjhm:"Responsible visitor phone",
		sfrsjhmS:"Responsible staff phone",
		xzrqsj:'Select date time',
		
        khkm:'Card number, open the door',
        glyid:'Administrator ID',
        yhm:'Username',
        mm:'Password',
        jsmc:'Role name',
        ymqx:'Page permissions',
        llkmc:'Flow card name',
        llkxh:'Flow card S/N',
        qysj:'Start time',
        zll:'Total flow',
        syll:'Residual flow',
        czsj:"Recharge time",
        czje:"Recharge amount",
		pltb:"Batch synchronization",

        tjzbm:'Add sub department',
        bmmc:'Department name',
        bjbm:'Edit department',
		zbmmc:'Sub department name',
        tsxx:'Prompt information',
		
		drmb:'Template',
		drsb:'Import Failed',
		hss:'Number of rows',
		yy:'Reason',

        yhmsryw:'Wrong user name input',
        yhmmsryw:'User password input error!',
        sccg:'Delete successfully!',
        scsbmyqx:'Delete failed: no permission!',
        scsb:'Delete failed!',
		bdcg:'Binding succeeded',
		bdsb:"Binding failed",
		zbdllk:'No flow card found',
        sfzcj:'Register device',
		sblxH:'Device type',
        kmys:'Door opening delay',
        bmqx:'Department authority',
        kzcs:'Extended parameters',
        xzsb:'New device',
        htjc:'vivo detection',
        sssc:"Real time upload",
        gbxxk:'Close message box',
        bgcl:'Constant brightness',
        bjlzp:'Dont record photos',
        bsczp:'Dont record photos',
        ewmsb:'Two dimensional code recognition',
        sbms:'Recognition pattern',
        dr:'Single person',
        ddr:'Many people',
        kq:'Open',
        gb:'Close',
        ylrxzp:'Preview portrait photos',
        xgryzl:'Modification of Personnel information',
        scsbryzlbcz:'Failed to delete: Personnel data does not exist!',
        tjryzl:'Personal information',
        dr:'Import',
        dc:'Export',
        zjlx:'Document type',
        sfz:'identity card',
        hz:'passport',
        jzz:'Residence permit',
        jsz:'drivers license',
        jg:'Native place',
        hj:'Household register',
        csrq:'Date of birth',
        mz:'Nation',
        kmmm:'Door opening code',
        yxqks:'Valid from ',
        yxqjs:'Valid to',
        zpcjyq:"Photo collection requirements:",
        zmrlmgbsz:"Face face bust free",
        rlhmzb:"The proportion of face images is more than 2 / 3",
        sbxlhhwz:'Or device serial number',
        tbcg:'Synchronization succeeded',
        tbsb:"Synchronization failed",
        cjsjd:'Create time period',
        sjmx:"Time description",
        bsmxzd:'[0 means unlimited, maximum 31]',
        xygljsd:'Next link period',
        yxxqxx:'Valid week options',
        mryxsq:'Valid time period',
        sq:'time',
        rysjd:'Any time point',
        xckz:'Time limit control',
        dyxc:'Month limit',
        dtxc:'Day limit',
        sqyxc:'Time zone 1 time limit',
        sqexc:'Time zone 2 Limited',
        sqsxc:'Time zone 3 Limited',
        qxzcs:'Please select times',
        xqy:'Monday',
        xqe:'Tuesday',
        xqs:'Wednesday',
        xqss:'Thursday',
        xqw:"Friday",
        xql:"Saturday",
        xqq:'Sunday',
        tjbc:"Add shift",
        bcmc:'Shift Name:',
        gzsc:'Working hours:',
        sbbxs:"Working hours: 8 hours",
        gzsj:"working hours",
        qssj:'Start time',
        jssj:"End time",
        xxsj:'Rest time',
        xgbc:'Modify shift',
        slbb:"List: day shift",
        tjbbgz:"Add schedule rule",
        gzmc:"Rule name",
        xzbc:'Select shift:',
        qxz:'Please select',
        gzr:"Working day:",
        tj:'Add',
		tjH:'Add to',
        yxyg:'Selected staff',
		yxygH:'Selected staff',
        xgbbgz:'Revision of scheduling rules',
        tjjr:'Add holiday',
        xgjjr:'Modify holidays',
        xgcg:'Modified successfully',
        xgsb:"Modification failed",
        qjlb:"Leave category:",
        mx:"Describe:",
        sjj:'compassionate leave',
        bjj:"sick leave",
        cch:'business travel',
        cjj:"maternity leave",
        njj:'annual leave',
        hsjq:'marriage and funeral',
        qtt:'other',
        tjsgqd:'Manual Check in',
        qdrq:'Sign in date:',
        xgqd:"Modify sign in",
        xkkm:'Door card, open the door',
        yckm:"Remote door opening",
        lykm:'Bluetooth door',
        mmkm:'Password, open the door',
        zwkm:"Fingerprint, open the door",
        rlkm:'Face opens the door',
        scgly:'Delete administrator',

        tjjsgly:'Add User',
        yhjs:'User roles',
        rsjl:'Personnel manager',
        czy:"operator",
        tjymqx:'Role permissions',
        yhmm:'Password',
        bcz:'Preservation',
        xgjsgly:'Edit User',
        czz:'Recharge',
        qrtcm:'are you sure you want to exit?',
        tcts:'Exit prompt',
        qdd:'Submit',
        qxx:'cancel',
        yhdl:'User login',
        sjhmsryw:'Wrong input of mobile phone number',
        kjfs:'Shortcut',
        dbsx:'To do list',
        glxtczsp:'Operation video',
        dshry:'Personnel to be audited',
        sjgll:'Time management',
        yllfryzp:'Preview visitors photos',
        djj:"Add visitor",
        zpdj:'Photo register',
        pzz:'photograph',
        zpxz:'selection',
        dksxt:'Turn on the camera',
        gbsxt:'Turn off the camera',
        lfxxdj:'Visit registration information',
        lfrr:'Visitors',
        dww:'Company',
        dhh:'Phone',
        cph:'Vehicle number',
        sxry:'Entourage',
        bfr:'Responsible person',
        bfsy:"Responsible staff",
        sbsq:'Authorization',
        xzyg:"Select employees",
        kssj:'Start time',
        lfr:'Visitors',
        azdz:'Installation address',
        szkqcs:'Attendance parameters',
        cddt:'Late',
        ynbscd:"Its not late",
        ysskg:'And more is absenteeism',
        tq:'Early arrival',
        ynbsct:'It not early leave',
        bk:'Card replacement',
        cmyyx:'Once a month',
        gzsjjs:'End of working hours',
        hsksjb:'After the card count overtime',
        zwzzyxsksj:"The valid period for swipe cards",
        bxcs:'Unlimited number of times',
        wsz:'No settings',
        fz:'min',
        xs:'h',
        t:'Day',
        cs:"second",
        mt:'Every day',
        fwyh:'Access users',
        ggwhsb:'Device replacement',
        ysbxlh:'Old device S/N',
        xshxlh:"New device S/N",
        fzqx:"Copy Permissions",
        thsb:'Replacement equipment',
        sbb:'Synchronization',
        fzcg:'Copy success!',
        fzsbybycz:'Copy failed: device already exists!',
        fzsb:'Copy failed!',
        thcg:'Replace successfully!',
        thsbsbycz:'Replacement failed: device already exists!',
        thsbb:'Replacement failed!',
        tbsbmyqx:'Synchronization failed: no permission!',
        miao:'second',
        sbbcz:'The device does not exist',
        qxcxsb:'"Please check the device first!"',
        qxxzbm:'Please select department first',
        qtxazwz:'Please fill in the installation location',
        sbtjcg:'Device added successfully',
        sbxgcg:'Device modified successfully',
        sbycz:'Device already exists:',
        ytjlgsb:'The device has been added',
        sbtjsb:'Device add failed',
        scsbt:'Remove device',
        xgsbt:'Modify device',
        gxsbsb:'Update device failed!',
        cwjxz:'File',
        tpcj:'Image clipping',
        tjcg:'Added successfully',
        khycz:'Card number already exists',
        lgsjdbnxt:'The two periods cannot be the same',
        ghycz:'Job number already exists',
        sjycz:'Data already exists',
        qsrmz:'Please enter your name',
        qsrsjhm:'Please input mobile phone number',
        qsrgh:'Please input job number',
        qsrzjh:'Please input ID number',
        ckyg:'View employees',
        qx:'jurisdiction',
        cksb:'View devices',
        scxzqx:'Delete selected permissions',
        scxzsbqx:'Delete selected device permissions',
        sbazwz:'Equipment installation position',
        jclx:'Type of in and out',
        sbt:'equipment',
        sjd:'time slot',
        yxsb:'Selected devices',
        bccg:'Saved successfully',
        bcsb:'Save failed',
        lfrxmbnwk:'Visitor name cannot be empty',
        bfwrbnwk:'The visited person cannot be empty',
        qxzsb:'Please select the device first',
        fkzccg:'Visitor registered successfully',
        fwzcsb:'Visitor registration failed',
        shcg:'Audit successful',
        zcrlsbbzx:'Registered face device is not online',
        shsb:'Audit failed',
        qxzxyshdfk:'Please select the visitors to be audited first',
        wsh:'Not reviewed',
        ysh:'Reviewed',
        sgqdz:'Manual sign in',
        qsrygxm:'Please input the employee name!',
        qxzyg:'Please select employee!',
        qszrq:"Please set the date!",
        qdtjcg:'Check in added successfully',
        qdtjsb:"Failed to add sign in",
        qdxgcg:"Sign in modification succeeded",
        qdxgsb:'Check in modification failed',
        kqchlz:'Attendance processing',
        scsbglybcz:'Delete failed: administrator does not exist!',
        scsbmyyhqxhzhcw:'Delete failed: no permission or account error!',
        gglymqxtj:'The administrator does not have permission to add',
        tjsbxmycz:'Add failed: name already exists!',
        xgsbxmycz:'Modification failed: name already exists!',
        tjsb:'Add failed',
        szcg:'Set successfully!',
        szsb:'Setting failed!',
        clcg:'Successfully processed!',
        clsb:'Processing failed!',
        rybcz:'The Personnel does not exist',
        scsbfkbcz:'Delete failed: visitor does not exist!',
        zcsbbcz:"The registered device does not exist",
        zcrlbcz:'Registered face does not exist',
        tjsbbmycz:'Add failed: Department already exists!',
        kqcl:'Time tracking',
        lfrm:'Visitor name',
        xtgly:"system administrator",
        tjqjcc:'Add exception',
        xgqjcc:'Modify leave for business trip',
        
        hs:'Hisilicon',
        xc:'Star Chen',
        changj:"Manufactor"

    },
    // 手机号国家区
    zgdl:'Chinese Mainland',
    zgxg:'Hong Kong, China',
    zgam:'Macao China',
    zgtw:'Taiwan, China',
    afh:"Afghanistan",
    ade:'Andorra',
    alq:'The United Arab Emirates',
    am:'Oman',
    agt:'Argentina',
    aejly:'Algeria',
    ymny:'Armenia',
    alb:'Aruba',
    adl:"Austria",
    asbj:'Azerbaijan',

    bhm:'Bahamas',
    bl:'Bahrain',
    bbds:'Barbados',
    blst:'Palestine',
    bels:'Belarus',
    bjst:'Pakistan',
    bls:'Belgium',
    cxx:'Korea',
    cdjny:'Equatorial Guinea',
    dg:'Germany',
    dm:'Denmark',
    jbt:'Djibouti',
    dmnjghg:'Dominican republic',
    els:'Russia',
    egde:'Ecuador',
    aj:'Egypt',
    aseby:'Ethiopia',

    fg:'France',
    fl:'Finland',
    flb:'Philippines',
    fdg:'Vatican',

    gby:'Gambia',
    ggghg:'Republic of the Congo',
    glby:'Columbia',
    gljy:'Georgia',
    gba:'Cuba',
    hg:'the republic of korea',
    hl:'Netherlands',
    hd:'Haiti',

    jbz:'Cambodia',
    jlbs:'Kiribati',
    jny:'Guinea',
    jbbw:"Zimbabwe",
    kml:'Cameroon',
    kmqt:'Cayman Islands',
    kny:'Kenya',
    kwt:'Kuwait',
    kldy:'Croatia',
    kte:'Qatar',
    lys:'Laos',
    lby:'Libya',
    lwd:'Rwanda',
    mg:'U.S.A',
    mlxy:'Malaysia',
    mxg:'Mexico',
    md:'Myanmar',
    mlg:"Morocco",
    nf:'South Africa',
    nbe:'Nepal',
    nrly:'Nigeria',
    nw:'Norway',
    plqd:'Palau Islands',
    pty:'Portugal',
    rb:'Japan',
    rd:'Sweden',
    rs:'Switzerland',
    stalb:'Saudi Arabia',
    sd:'Sudan',
    slxy:'Saint Lucia',
    tg:'Thailand',
    tjs:'Tonga',
    teq:'turkey',
    tns:"Tunisia",
    wnrl:'Venezuela',
    wl:'Brunei',
    wkl:'Ukraine',
    xxl:'New Zealand',
    xjb:'Singapore',
    xby:'Spain',
    xl:'Greece',
    xly:'Syria',
    xyl:'Hungary',
    ydl:'Italy',
    yg:'britain',
    ydnxy:'Indonesia',
    yd:'India',
    yn:'Vietnam?',
    yl:'Iran',
    ylk:'Iraq',
    ysl:'Israel',
    ym:'Yemen',
    zl:'Chile',
    zfghg:'Central African Republic',



    yhmbcz:'user name does not exist',
    yhmycz:'User name already exists',
    qtxyhm:'Ple fill in the user name',
    hqsb:'Acquisition failed',
    yhmbnwk:'User name cannot be empty',
    mmbnwk:'Password cannot be empty',
    gsmcbnwk:'Company name cannot be empty',
    sjhmbnwk:"Mobile phone number cannot be empty",
    yzmbnwk:'Verification code cannot be empty'
    
}