import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 加载全局样式
import './styles/index.less'
// 引入ElementUI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import { serve } from '@/api/uesr'
import animated from 'animate.css'
// import Vuex from 'vuex'
import VueCropper from 'vue-cropper'
import md5 from 'js-md5'
import axios from 'axios'
import XLSX from 'xlsx'
import VCharts from 'v-charts'
import Headbuttom from '@/components/hedbttom'
//引入svg组件
import IconSvg from './components/IconSvg'

import cn  from './lang/cn'    // 引入lang文件
import  en from './lang/en'
import  re from './lang/re'

import elementEnLocale from 'element-ui/lib/locale/lang/en' // element-ui lang
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'// element-ui lang
import elementReLocale from 'element-ui/lib/locale/lang/ru-RU'// element-ui lang
import VueI18n from 'vue-i18n'
import wl from "wl-vue-select"
import "wl-vue-select/lib/wl-vue-select.css"
Vue.use(wl);
Vue.use(VueI18n) 
// 播放器 



const i18n = new VueI18n({
  locale: 'cn',   // 设置语言
  messages: {
    cn:{
      ...cn,
      ...elementZhLocale
    } ,
    en:  {
      ...en ,
      ...elementEnLocale
    },
	re:  {
	  ...re ,
	  ...elementReLocale
	}
  }
})
initLocalLang()


function initLocalLang () {
  // element-ui组件国际化
  Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
  })
}
// //全局注册icon-svg
Vue.component('Headbuttom', Headbuttom)
Vue.component('icon-svg', IconSvg)
Vue.prototype.XLSX = XLSX
Vue.use(VCharts)

let userData=window.localStorage.getItem('user');
let UserInfo = JSON.parse(window.localStorage.getItem('user'));
Vue.prototype.userData =userData
Vue.prototype.UserInfo =UserInfo

// axios.defaults.baseURL = 'http://web.fondcard.net/'
// Vue.prototype.$http = axios



Vue.use(animated)
Vue.prototype.$md5 = md5
Vue.prototype.$serve = serve
Vue.use(ElementUI)
Vue.use(VueCropper)
// Vue.use(Vuex)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
