
// retqui''
require("../../public/config")
// console.log(`909`,CONFIG)

// return false
const methods={
    // CONFIG :ss.CONFIG,
    base_url: CONFIG.baseUrl,
    //图片上传基本地址
    upload_img_base: `${CONFIG.baseUrl}open/store/api.php?`,// `${CONFIG.baseUrl}open/store/api.php?`,
    //人脸图片路径
    type_face: CONFIG.type_face,
    //人脸模板路径
    type_temp: CONFIG.type_temp,
    //图片预览地址
    resident_pic_base: `${CONFIG.baseUrl}static`,

    type_head: CONFIG.type_head,
    
    // 短信验证码获取
    ee_sms_code : 'co.sms.code.get',
    // 注册
    ee_register: 'co.register',
    // 忘记密码
    ee_reset_pwd: 'co.pwd.reset',
    // 登录
    ee_login: 'co.login',
    // 企业资料修改
    ee_enterprise_edit: 'co.enterprise.edit',
    // 企业资料获取
    ee_enterprise_get: 'co.enterprise.get',
    // 企业资料列表
    ee_enterprise_list: 'co.enterprise.list',
    // 部门新增
    ee_dept_add: 'co.dept.add',
    // 部分设置上下级
    ee_dept_level_set: 'co.dept.level.set',
    // 部分修改
    ee_dept_edit: 'co.dept.edit',
    // 部分删除
    ee_dept_del: 'co.dept.del',
    // 部门列表
    ee_dept_list: 'co.dept.list',

    //设备查询
    ee_device_search: 'fvcard.device.info.get',
    //设备新增
    ee_device_add: 'co.device.add',
    //设备列表
    ee_device_list: 'co.device.list',
    //设备获取
    ee_device_get: 'co.device.get',
    //设备删除
    ee_device_del: 'co.device.del',
    //设备修改
    ee_device_edit: 'co.device.edit',
    //设备状态列表
    ee_dev_state_list: 'co.device.state.list',

    //设备授权管理列表
    //ee_key_device_list: 'co.key.manage.device.list',
    //访客住户选择
    ee_visitor_person_sel: 'co.visit.person.select',
    //访客获取
    ee_visitor_get: 'co.visit.get',
    //访客住户设备列表
    ee_visitor_dev_list: 'co.visit.person.device.list',
    //访客登记
    ee_visitor_add: 'co.visit.add',
    //访客删除
    ee_visitor_del: 'co.visit.del',
    //访客预约批量审核
    ee_visitor_batch_approve: 'co.visit.more.approve',
    //访客预约管理列表
    ee_visitor_approve_list: 'co.visit.approve.list',
    //更新人员图片
    ee_update_person_pic: 'co.person.pic.update',

    //通行列表
    ee_open_door_list: 'co.open.door.list',
    //非法记录列表
    ee_illegal_open_list: 'co.invalid.open.door.list',
    //照片记录列表
    ee_pic_open_door_list: 'co.snapshot.door.list',

    //设备复制
    ee_device_copy: 'co.device.copy',
    //设备维护:替换
    ee_device_replace:'co.device.replace',
    //设备同步
    ee_device_syn: 'co.device.key.syn',

    //管理员列表
    ee_manager_list: 'co.manager.list',
    //添加管理员
    ee_manager_add: 'co.manager.add',
    //管理员获取
    ee_manager_get: 'co.manager.get',
    //管理员修改更新
    ee_manager_update: 'co.manager.update',
    //管理员删除
    ee_manager_del: 'co.manager.del',

    //流量卡列表
    ee_flow_card_list: 'co.flow.card.list',
    //流量卡同步
    ee_flow_card_syn: 'co.flow.card.syn',
    //流量卡批量同步
    ee_flow_card_more_syn: 'co.flow.card.more.syn',

    //更新用户头像
    ee_profile_update: 'co.profile.update'
}

// 导出请求方法
export default methods