import Vue from 'vue'
import Vuex from 'vuex'
import breadtab from './breadcrumb'
import selectree from './selectree'
import lang from './lang'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    breadtab,
    selectree,
    lang
  }
})
