module.exports={
    // 头部菜单
    header_meun:{
        logout: "выход"
    },
    // 左侧导航
    adminhou:'система управления',
    adminname:'система',
    navLeft:{
        qygl:"Компания",
        qyzl:"О Компании",
        qyjg:'Структура компании',
        add:'Добавить',
		addR:'Добавить правило',
		addU:'Добавить пользователя',
        sbgl:'Оборудование',
		sbglH:'Управление устройствами',
        ztcx:'Запрос статуса',
        yggl:'Сотрудники',
		ygglH:'Сотрудники',
        ryzl:'Персональные данные',
		ryzlH:'Справочник сотрудников',
        zzsh:'Аудит сотрудников',
        qxgl:'Управление полномочиями',
        sjgl:'Управление временем',
        fkgl:'Гости',
        fkdj:'Регистрация гостя',
        yysh:'Встречи',
        kqgl:'Управление посещаемостью',
		kqsz:'Настройки посещаемости',
        sjly:'Источники данных',
        kqcs:'Параметры учета',
        kqbc:'Рабочий график',
        kqpb:'Графики посещаемости',
        jrgl:'Расписание праздников',
        qjgl:'Контроль исключений',
        sgqd:'Ручная регистрация',
        kqcl:'Учет времени',
        bbsj:'Отчеты',
        txjl:'Список событий',
        kqrb:'Дневной учет раб. Времени',
        kqyb:'Месячный учет раб.времени',
        fkjl:'Гостевые события',
        ffjl:'Нарушения',
        zpjl:'Записи камер',
        xtsz:'Системные настройки',
        glysz:'Настройки Администратора',
        llksz:'запрос карты расхода',
        bcgl:'сменное управление',
        hkbk:'плохая карта',
        qdgl:'управление записями',
        sjtb:"синхронизация данных",
		zncw:"интеллигентный номер",
		sscw:"режим реального времени",
		cwgl:"управление местами",
		ccxx:"Информация о парке",
		tjkqcl:'Добавить учет времени',
    },
	LaterOhther:{
		bjjlcl:'обработка записи напоминания',
		znIPC:'интеллектуальный IPC',
		bjcljl:'запись обработки напоминания',
		szbjts:'Настройка напоминания @ info: whatsthis',
		tsbjjl:'запись напоминания',
		ssspck:'видео в реальном времени',
		spckjl:'запись видео'
	},
    tablename:{
        scewm:'генерация двухмерного кода',
        xgqyzl:'О Компании',
		xgqyzlH:'О Компании',
        gsmc:'название компании',
		gsmcH:'Наименование',
        lxdh:'Контактный номер',
        yxdz:'E-mail',
        szsf:'провинция',
        szcs:'город',
        xxdz:'Адрес',
        qd:'Применить',
        qymc:'название компании',
        cz:'Действие',
        zj:"Добавить",
        sc:'Удалить',
        bj:'Редактировать',
		bjH:'Изменить',
		jsj:'плюс время',
		sjqh:'телефонный код'
    },
    devtable:{
		tjH:'Добавить в',
		rq:'дата ',
        rqgs:'формат даты',
        moth:'месяц',
        data:'день',
        sbh:'номер устройства',
        sbbzx:'устройство не подключено',
		czxcxdlmm:'сбросить пароль для входа в систему',
        zzmm:'сбросить пароль',
        zzcg:'сбросить успешно',
        zzsb:'ошибка сброса',
        qc:'Очистить',
        gx:'Обновить',
        km:'открыть дверь',
        wh:'Обслуживание',
        xg:'Изменить',
        cq:'Перезагрузить',

        sb:'Устройство',
		sbH:'Устройства',
        azwz:'Расположение',
        kmzt:'Направление прохода',
        kmms:'Режим',
		kmmsI:'Идентификатор',
        sbxlh:'Номер устройства',
        zxzt:'Статус устройства',
        sblx:'Тип оборудования',
        zcj:'Регистрация устройства',
		llk:"расходомерная карта",

        ysms:'режим задержки',
        ckms:'нормально открытый режим',
        cbms:'нормально закрытый режим',
        frlj:'бесчеловечная машина',
        rlj:'физиономия',
        zx:'онлайн',
        lx:'оффлайн',
        s:'Да',
        f:'Нет',
        j:'Вход',
        c:'Выход',
        kmys:'задержка открытия',

        sbmc:'Наименование устройства',
		sbxlhD:'Номер устройства',
        sbzt:'Статус устройства',
        wxz:'выбор',
        cx:'Запрос',

        rlsl:'Кол-во лиц',
        mksl:'Кол-во карт',


        zcid:'Номер документа',
        xm:'Имя',
        gh:'Номер сотрудника',
		ghH:'Раб. Телефон',
		ghHH:'Номер сотрудника',
        qybm:'Отдел',
        xb:'Пол',
        sjhm:'Телефон',
        zjhm:'Таб. Номер',
		zjhmH:'Номер документа',
        kh:'Номер карты',
        rxzp:'Фото',
        yl:'Просмотр',
        male:'мужчина',
        female:'женщина',

        shzt:'Статус аудита',
        rzlx:'тип аутентификации',
        shr:'аудитор',
        shsj:"Время аудита",
        zczt:'Статус регистрации',
        ysp:'утверждено',
        wsp:'Не подтвержден',

        wzc:'регистрация',
        yzc:'регистрация',
        bfzc:'частичная регистрация',
        zcsb:'Ошибка регистрации',
        yg:"Сотрудник",
        yl:'Просмотр',
        bm:'Отдел',
		sjhmN:'Телефон',
        sh:'Проверить',
        plsh:'оптовая проверка',
        qbsh:'полная проверка',
        rylx:'Тип персонала',
		rylxS:'Тип сотрудника',
        tbsj:'Время синхронизаии',
        tbzt:'Статус синхронизации',
        qxck:'Разрешения',
        fk:'посетитель',
        wtb:'синхронизация',
        ry:'персонал',
        lwtb:'асинхронность лица',
        kwtb:'синхронизация карт',
        kmmmwtb:'не синхронизировано открытие пароля',
        ytb:"синхронизация",
        bzx:'неавтономный',
        ymj:'запреты',

        sdh:'Номер периода',

        qsrq:"Дата начала",
        jzrq:'Дата конца',
        sjms:'Описание',
        xygljsd:'Следующий период',
        cj:'Установить',
        qdjcx:'Нажмите Запрос',

        lfrxm:'Имя гостя'   ,
        bfrbm:'Ответственный отдел',
		bfrbmT:'Отвественный отдел',
        bfr:'Ответственный сотрудник',
		bfrr:'Ответственный сотрудник',
        bfrsjhm:"Телефон ответ. Сотрудника",
        lfrzp:"Фото гостей",
		lfrzpD:'Фото гостя',
        lfsj:"Время визита",
        lfcph:"Номер транспорта",
        tb:'синхронизация',

        tjsbkq:'Добавить устройство учета',
		tjsbkqH:'Добавить устройство учета',

        mc:'Наименование',
        dklx:'Тип открытия',
        sbsjd:'Рабочие часы',

        gzmc:'Имя правила',
		gzmcH:'Наименование правила',
        bcjgz:"Повторения и Правила",
        yyfw:'область применения',
        xh:'Серийный номер',

        jjrmc:'Наименование',
        ksrq:'Начало:',
        xzrq:"Выбрать дату",
        jsrq:'Конец',
        ms:'Описание',
        qjlx:'Причина отсутствия',
		qjlxH:'Тип исключения',
		ksrqH:'Дата начала',
		jsrqH:'Дата конца',

        ygid:'Номер сотрудника',
        ygxm:'Имя сотрудника',
        qdsj:'Время регистрации',
        bz:'Замечание',
        bc:'Рабочий график',

        tgsj:'Время события',
        zt:'Состояние',
        kmlx:'Тип открытия двери',

        skrq:'Дата события',
        cdfz:'Опоздание (мин)',
        ztfz:'Ранний уход (мин)',
        jbfz:'Переработка (мин)',
        sk:'щётка',
        yf:'Месяц',
        yqts:'Рабочих дней по графику',
        sqts:'Рабочих дней актуально',
        cdcs:'Опоздание (мин)',
        ztcs:'Ранний уход (мин)',
        yskcs:'Отметок по графику',
        wskcs:'Не отметился',
        qjt:"Отсутствовал (дней)",
        cct:'Командировка (дней)',
        jbxs:'Переработка (мин)',
        kgt:'Прогул (дней)',


        sfr:'Ответственный за гостя',
		kssjS:'Начало',
		jssjS:'Конец',
        sfrsjhm:'Телефон Ответственного за гостя',
		sfrsjhmS:'Телефон Ответственного за гостя',
		xzrqsj:'время выбора даты',

        khkm:'калиберный дверь',
        glyid:'Администратор ID',
        yhm:'Пользователь',
        mm:"Пароль",
        jsmc:'Роль',
        ymqx:'права страницы',
        llkmc:'имя карты расхода',
        llkxh:'порядковый номер карточки расхода',
        qysj:'Время начала',
        zll:'общий расход',
        syll:'остаточный расход',
        czsj:'время наполнения',
        czje:'полная сумма',
		pltb:'массовая синхронизация',

        tjzbm:'Добавить под.отдел',
        zbmmc:'Наименование',
		bmmc:'Имя отдела',
        bjbm:'Изменить отдел',
        tsxx:'подсказка',

        yhmsryw:'Ошибка ввода имени пользователя!',
        yhmmsryw:'Ошибка ввода пароля пользователя!',
        sccg:'Удалено успешно!',
        scsbmyqx:'Ошибка удаления: нет прав!',
        scsb:'Ошибка удаления!',
		bdcg:"связать успешно",
		bdsb:"Ошибка привязки",
		zbdllk:'карта расхода не найдена',

        sfzcj:'Регистрация устройства',
		sblxH:'Тип устройства',
        bmqx:'Ответственный отдел',
        kzcs:'параметр расширения',
        xzsb:'Новое устройство',
        htjc:'обнаружение живых тел',
        sssc:'Загрузка в реальном времени',
        gbxxk:'закрыть окно',
        bgcl:'Подсвечивать, Подсвечивать',
        bjlzp:'незарегистрированный снимок',
        bsczp:'Не загружать фото',
        ewmsb:'распознавание двухмерного кода',
        sbms:'режим распознавания',
        dr:'одиночка',
        ddr:'много людей',
		drsb:'Ошибка импорта',
        kq:'открыть',
        gb:'закрыть',
		hss:'число строк',
		yy:'причина',

        ylrxzp:'портретный снимок',
        xgryzl:'Изменить данные личного состава',
        scsbryzlbcz:'Ошибка удаления: данные о персонале отсутствуют!',
        tjryzl:'Персональная информация',
        dr:'Импорт',
        dc:'Экспорт',
		drmb:'опалубка ',

        zjlx:'Тип документа',
        sfz:'удостоверение личности',
        hz:'паспорт',
        jzz:'вид на жительство',
        jsz:'водительские права',
        jg:'место рождения',
        hj:"местожительство",
        csrq:'Дата рождения',
        mz:'нация',
        kmmm:'открыть пароль',
        yxqks:'Начало действия',
        yxqjs:'Конец действия',
        zpcjyq:'требования к сбору фотографий：',
        zmrlmgbsz:'лицо спереди без головного убора',
        rlhmzb:'портрет человека выше 2 / 3',
        sbxlhhwz:'номер или расположение устройства',

        tbcg:'синхронизация прошла успешно',
        tsb:'ошибка синхронизации',
        cjsjd:'Создать расписание',
        sjmx:'описание времени',
        bsmxzd:'[0 - без ограничений, максимум 31]',
        xygljsd:"Следующий период",
        yxxqxx:'Дни недели',
        mryxsq:'Периоды времени',
        sq:'часовой пояс',
        rysjd:'произвольная точка времени',
        xckz:'Ограничение времени',
        dyxc:'Месяцы',
        dtxc:"Дни",
        sqyxc:'часовой пояс 1',
        sqexc:'часовой пояс 2',
        sqsxc:'часовой пояс 3',
        qxzcs:'Выберите число',
        xqy:'понедельник',
        xqe:'Вторник',
        xqs:'среда',
        xqss:'четверг',
        xqw:"пятница",
        xql:"суббота",
        xqq:'воскресенье',

        tjbc:'Рабочий график',
        bcmc:'Наименование ：',
        gzsc:"Рабочие часы：",
        sbbxs:"время работы 8 часов",
        gzsj:'Рабочие часы',
        qssj:"Время начала ",
        jssj:'Время конца',
        xxsj:'Время отдыха',
        xgbc:"изменить рейс",
        slbb:"белый класс",
        tjbbgz:"Правило посещаемости",
        gzmc:"Имя правила：",
        xzbc:"Выберите график：",
        qxz:"Выберите пожалуйста",
        gzr:"Рабочий день：",
        tj:"Добавить",
        yxyg:"Выбранные сотрудники",
		yxygH:"Выбраные сотрудники",
        xgbbgz:'Изменить правило очередности',
        tjjr:'Добавить праздник',
        xgjjr:"Изменить праздники",
        xgcg:'изменение завершено',
        xgsb:'Ошибка изменения',
        qjlb:'категории отпусков：',
        mx:"описывать：",
        sjj:'служебный отпуск',
        bjj:"отпуск",
        cch:'командировка',
        cjj:"отпуск по беременности",
        njj:'ежегодный отпуск',
        hsjq:'свадьба',
        qtt:'Прочее',
        tjsgqd:'Ручная регистрация',
        qdrq:'дата подписания：',
        xgqd:'Изменить надпись',
        xkkm:'дверной замок',
        yckm:"дистанционный дверь",
        lykm:'Синезубый дверь',
        mmkm:'шифровальная дверь',
        zwkm:"отпечаток пальца",
        rlkm:'дверь с человеческим лицом',

        scgly:'Удалить Администратора',

        tjjsgly:'Добавить пользователя',
        yhjs:'Роли',
        rsjl:'управление кадрами',
        czy:"оператор",
        tjymqx:'Права роли',
        yhmm:"Пароль",
        bcz:'Сохранить',
        xgjsgly:'Изменить пользователя',
        czz:'наполнение',
        qrtcm:'Подтверждение выхода？',
        tcts:'выход подсказки',
        qdd:'определение',
        qxx:'отмена',
        yhdl:'пользователь',

        sjhmsryw:'Ошибка при вводе номера телефона',

        kjfs:'Комбинация клавиш',
        dbsx:'задача',
        glxtczsp:'видео для работы с системой управления',
        dshry:'лица, ожидающие проверки',
        sjgll:'Общий обзор данных：',
        yllfryzp:'просмотр фотографий посетителей',
        djj:"Добавить гостя",
        zpdj:'регистрация фотографий',
        pzz:'фотографировать',
        zpxz:'выбор снимка',
        dksxt:'открыть веб - камеру',
        gbsxt:'закрыть камеру',
        lfxxdj:'регистрация посетителей',
        lfrr:'Гости',
        dww:'Компания',
        dhh:"Телефон",
        cph:'Номер машины',
        sxry:'сопровождающий',
        bfr:'Отвественный сотрудник',
        bfsy:'Ответственный ',
        sbsq:'разрешение устройства',
        xzyg:"выбор персонала",
        kssj:'Начало',
        lfr:'Гости',
        azdz:'монтажный адрес',
        szkqcs:"Параметры учета",

        cddt:'Опоздание',
        ynbscd:'Не считать опозданием',
        ysskg:'И больше считать прогулом',
        tq:'Ранний приход',
        ynbsct:'не раньше',
        bk:'Замена карты',
        cmyyx:'Раз в месяц',
        gzsjjs:'Конец рабочего времени',
        hsksjb:'После сверхурочного подсчета карт',
        zwzzyxsksj:'Период регистрации ',
        bxcs:'неограниченное число',
        wsz:'Нет настройки',
        fz:'мин',
        xs:'ч',
        t:'День',
        cs:'раз',
        mt:'каждый день',
        fwyh:'Доступ к пользователю',
        ggwhsb:"Замена устройства",
        ysbxlh:'Номер старого устройства',
        xshxlh:'Номер нового устройства',
        fzqx:"Копировать права доступа",
        thsb:"сменное устройство",
        sbb:'синхронизация',
        fzcg:'Копировать успешно!',
        fzsbybycz:'Ошибка копирования: устройство уже существует!',
        fzsb:'Ошибка копирования!',
        thcg:'замена прошла успешно!',
        thsbsbycz:'ошибка замены: устройство уже существует!',
        thsbb:"ошибка замены!",
        tbsbmyqx:'ошибка синхронизации: нет прав!',
        miao:'секунда',
        sbbcz:'устройство не существует',
        qxcxsb:'устройство запроса!',
        qxxzbm:'Выберите сектор',
        qtxazwz:'Заполните, пожалуйста, место установки',
        sbtjcg:'устройство добавлено успешно!',
        sbtjsb:'Ошибка добавления устройства',
        sbxgcg:'устройство изменено успешно!',
        sbycz:'оборудование уже существует:',
        ytjlgsb:'добавлено устройство',
        scsbt:'удалить устройство',
        xgsbt:'Изменить устройство',
       gxsbsb :'Ошибка обновления устройства!',
       cwjxz:'выбрать из файла',
       tpcj:'фотомонтаж',
       tjcg:'Добавление успешно',
       khycz:'номер карты уже существует',
       lgsjdbnxt:'не одно и то же время',
       ghycz:'рабочий номер уже существует',
       sjycz:'данные уже существуют',
       qsrmz:'Введите имя',
       qsrsjhm:'Введите номер телефона',
       qsrgh:'Введите рабочий номер',
       qsrzjh:'Введите номер документа.',
       ckyg:'персонал',
       qx:"права",
       cksb:'Просмотр устройства',
       scxzqx:'Удалить выделенные права',
       scxzsbqx:'Удалить права доступа к выбранному устройству',
       sbazwz:'место установки оборудования',
       jclx:'Тип импорта',
       sbt:'устройство',
       sjd:'промежуток времени',
       yxsb:'выбранное оборудование',
       bccg:'сохранить успешно',
       bcsb:'Ошибка сохранения',
       
       lfrxmbnwk:'имя посетителя не может быть пустым!',
       bfrbnwk:'Доступ не может быть пустым!',
       qxzsb:'Выберите устройство!',
       fkzccg:'регистрация посетителей прошла успешно！',
       fkzcsb:'Ошибка регистрации посетителей',
       shcg:'успешно рассмотреть',
       zcrlsbbzx:'устройство регистрации лиц не работает',
       shsb:'неудача проверки',
       qxzxyshdfk:'Сначала выберите посетителя для проверки',
       wsh:'Рассмотрение',
       ysh:'Проверено',
       sgqdz:'вручную расписаться',
       qsrygxm:'Введите имя работника!',
       qxzyg:'Выберите сотрудников!',
       qszrq:'Укажите дату!',
       qdtjcg:'Регистрация успешно добавлена',
       qdtjsb:'Ошибка добавления подписи',
       qdxgcg:'подписать изменения успешно',
       qdxgsb:"Ошибка чтения",
       kqclz:'учет рабочего времени',
       scsbglybcz:'Ошибка удаления: администратор не существует!',
       scsbmyyhqxhzhcw:'Ошибка удаления: нет прав доступа или номера счёта!',
       gglymqxtj:'администратор не имеет права добавлять',
       tjsbxmycz:'Ошибка добавления: имя уже существует!',
       xgsbxmycz:'Ошибка изменения: имя уже существует!',
       tjsb:'Ошибка добавления',
       szcg:'Настройка выполнена！',
       szsb:'Ошибка установки！',
       clcg:'успешная обработка！',
       clsb:'Ошибка обработки！',
       rybcz:'отсутствие персонала',
       scsbfkbcz:'Ошибка удаления: посетителей не существует!',
       zcsbbcz:'регистрирующее устройство не существует',
       zcrlbcz:'лицо регистратора не существует',
       tjsbbmycz:'Ошибка добавления: сектор уже существует!',
        kqcl:'Учет времени',
        lfrm:'Имя гостя',
        xtgly:'системный администратор',
        tjqjcc:'Добавить исключение',
        xgqjcc:'изменение командировок в отпуск',
        
        hs:'хайсы',
        xc:'императорский дворец',
        changj:'завод'
    },
    // 手机号国家区
    zgdl:'Chinese Mainland',
    zgxg:'Hong Kong, China',
    zgam:'Macao China',
    zgtw:'Taiwan, China',
    afh:"Afghanistan",
    ade:'Andorra',
    alq:'The United Arab Emirates',
    am:'Oman',
    agt:'Argentina',
    aejly:'Algeria',
    ymny:'Armenia',
    alb:'Aruba',
    adl:"Austria",
    asbj:'Azerbaijan',
    
    bhm:'Bahamas',
    bl:'Bahrain',
    bbds:'Barbados',
    blst:'Palestine',
    bels:'Belarus',
    bjst:'Pakistan',
    bls:'Belgium',
    cxx:'Korea',
    cdjny:'Equatorial Guinea',
    dg:'Germany',
    dm:'Denmark',
    jbt:'Djibouti',
    dmnjghg:'Dominican republic',
    els:'Russia',
    egde:'Ecuador',
    aj:'Egypt',
    aseby:'Ethiopia',
    
    fg:'France',
    fl:'Finland',
    flb:'Philippines',
    fdg:'Vatican',
    
    gby:'Gambia',
    ggghg:'Republic of the Congo',
    glby:'Columbia',
    gljy:'Georgia',
    gba:'Cuba',
    hg:'the republic of korea',
    hl:'Netherlands',
    hd:'Haiti',
    
    jbz:'Cambodia',
    jlbs:'Kiribati',
    jny:'Guinea',
    jbbw:"Zimbabwe",
    kml:'Cameroon',
    kmqt:'Cayman Islands',
    kny:'Kenya',
    kwt:'Kuwait',
    kldy:'Croatia',
    kte:'Qatar',
    lys:'Laos',
    lby:'Libya',
    lwd:'Rwanda',
    mg:'U.S.A',
    mlxy:'Malaysia',
    mxg:'Mexico',
    md:'Myanmar',
    mlg:"Morocco",
    nf:'South Africa',
    nbe:'Nepal',
    nrly:'Nigeria',
    nw:'Norway',
    plqd:'Palau Islands',
    pty:'Portugal',
    rb:'Japan',
    rd:'Sweden',
    rs:'Switzerland',
    stalb:'Saudi Arabia',
    sd:'Sudan',
    slxy:'Saint Lucia',
    tg:'Thailand',
    tjs:'Tonga',
    teq:'turkey',
    tns:"Tunisia",
    wnrl:'Venezuela',
    wl:'Brunei',
    wkl:'Ukraine',
    xxl:'New Zealand',
    xjb:'Singapore',
    xby:'Spain',
    xl:'Greece',
    xly:'Syria',
    xyl:'Hungary',
    ydl:'Italy',
    yg:'britain',
    ydnxy:'Indonesia',
    yd:'India',
    yn:'Vietnam?',
    yl:'Iran',
    ylk:'Iraq',
    ysl:'Israel',
    ym:'Yemen',
    zl:'Chile',
    zfghg:'Central African Republic',

    yhmbcz:'Имя пользователя не существует',
    yhmycz:'Имя пользователя уже существует',
    qtxyhm:'Введите имя пользователя',
    hqsb:'Ошибка получения',
    yhmbnwk:'Имя пользователя не может быть пустым',
    mmbnwk:'Пароль не может быть пустым',
    gsmcbnwk:'название компании не может быть пустым',
    sjhmbnwk:"номер телефона не может быть пустым",
    yzmbnwk:'код проверки не может быть пустым'

}