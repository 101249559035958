import request from '@/utils/request'
var base_url = CONFIG.baseUrl
// var base_url2 = CONFIG.baseUrl2
if(base_url.indexOf('https:') == 0 && document.location.protocol != 'https:' && location.href.indexOf('//127.0.0.1') == -1 && location.href.indexOf('//localhost') == -1 && location.href.indexOf('//192.') == -1 && location.href.indexOf('//10.') == -1)
{
  base_url =  location.replace('https://'+location.href.substring(7));
}
export const serve = data => {
  return request({
    method: 'POST',
    url:`open/youxing/services.php`,
		// url:`open/enterprise/services.php`,
		 //${base_url}open/enterprise/services.php`,
    data
  })
}



export const devsearch = data => {
  return request({
    method: 'POST',
    url: `https://www.fondcard.net/open/sc_services.php`,
    data
  })
}

export const uploadPic = data =>{
  return request({
    method: 'POST',
    url: `static/open/store/api.php?`, //`${base_url}static/open/store/api.php?`,
    data
  })
}

export const attendanceServer = data =>{
  return request({
    method: 'POST',
    url: `open/youxing/services.php`, //`${base_url}open/enterprise/services.php`,
    data
  })
}
