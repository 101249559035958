<template>
  <div class="header_roe">
        <!-- <i
        style="line-height: 32px;"
        :class="{
        'el-icon-s-fold': isCollapse,
        'el-icon-s-unfold': !isCollapse
        }"
        @click="isCollapse = !isCollapse"
    ></i> -->
        <div class="header_brand">
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{}">{{$t($route.meta.module)}}</el-breadcrumb-item>
            <el-breadcrumb-item>{{$t($route.meta.title)}}</el-breadcrumb-item>
        </el-breadcrumb>
    </div>
    <!-- <div style="margin-left:30px">
        <slot name="headerButtom"></slot>
    </div> -->
    </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data(){
    return{
      fathcurrentMenugt:window.localStorage.getItem('fathcurrentMenu'),
      currentMenugt:window.localStorage.getItem('currentMenu')
    }
  },

    computed:{
    ...mapState({
      current: state=>state.breadtab.currentMenu,
      facurrent: state => state.breadtab.fathcurrentMenu
    })
    
  },
}
</script>

<style lang="less" scoped>
.header_roe{
    
    display: flex;
    align-items:center;
    .header_brand{
        margin-left: 20px;
        line-height: 32px;
    }
    
  }
</style>