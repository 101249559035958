<template>
<div class="aios">
   <el-menu
   
        :unique-opened="true"
        :collapse="isCollapse"
         router
        :default-active="$route.path"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        background-color="#304156"
        text-color="#eee"
        
        active-text-color="#fff">
        <div class="asdetitle">
            <div class="titlecontent">
              <router-link to="/home">
                <icon-svg icon-class="zhy" class="iii" />
                <span v-if="!isCollapse">{{$t('adminhou')}}</span>  
                <span v-else>{{$t('adminname')}}</span> 
              </router-link> 
            </div>
            
        </div>
        
        <!-- <el-submenu  :index="item.id" v-for="item in navmenlist" :key="item.id" @click="clickfaMenu(item)">
          <template slot="title" @click="clickfaMenu(item)">
            <i class="el-icon-menu" @click="clickfaMenu(item)"></i>
            <span @click="clickfaMenu(item)">{{$t(item.authname)}}</span>
          </template>
          <el-menu-item-group >
            <el-menu-item :index="subitem.path" v-for="subitem in item.childrens"  @click="clickMenu(subitem)" :key="subitem.id">{{ $t(subitem.authname) }}</el-menu-item>
          </el-menu-item-group>
        </el-submenu> -->

        <el-submenu index="1" v-if="(menu.indexOf('1') !=-1 || menu.indexOf('2') !=-1) && UserInfo.is_dinner_sys==0">
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span>{{$t('navLeft.qygl')}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/information" @click="ssy('/information')" v-if="menu.indexOf('1') !=-1">{{$t('navLeft.qyzl')}}</el-menu-item>
            <el-menu-item index="/structure" @click="ssy('/structure')" v-if="menu.indexOf('2') !=-1">{{$t('navLeft.qyjg')}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="2" v-if="menu.indexOf('3') !=-1 || menu.indexOf('4') !=-1">
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span>{{$t('navLeft.sbgl')}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/devicemanagem" @click="ssy('/devicemanagem')" v-if="menu.indexOf('3') !=-1">{{$t('navLeft.sbglH')}}</el-menu-item>
						<el-menu-item index="/accessControl" @click="ssy('/accessControl')">{{$t('navLeft.mjjz')}}</el-menu-item>
            <el-menu-item index="/statusquery" @click="ssy('/statusquery')" v-if="menu.indexOf('4') !=-1">{{$t('navLeft.ztcx')}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="3" v-if="(menu.indexOf('5') !=-1 || menu.indexOf('6') !=-1 || menu.indexOf('7') !=-1|| menu.indexOf('8') !=-1) && UserInfo.is_dinner_sys==0">
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span>{{$t('navLeft.yggl')}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/perinformation" @click="ssy('/perinformation')" v-if="menu.indexOf('5') !=-1">{{$t('navLeft.ryzl')}}</el-menu-item>
            <el-menu-item index="/selfaudit" @click="ssy('/selfaudit')" v-if="menu.indexOf('6') !=-1">{{$t('navLeft.zzsh')}}</el-menu-item>
            <!-- <el-menu-item index="/timanage" @click="ssy('/timanage')" v-if="menu.indexOf('8') !=-1">{{$t('navLeft.sjgl')}}</el-menu-item> -->
            <el-menu-item index="/authmanagem" @click="ssy('/authmanagem')" v-if="menu.indexOf('7') !=-1">{{$t('navLeft.qxgl')}}</el-menu-item>
            <el-menu-item index="/synchro" @click="ssy('/synchro')" v-if="menu.indexOf('7') !=-1">{{$t('navLeft.sjtb')}}</el-menu-item>
            
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="9" v-if="this.$i18n.locale == 'cn'">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span>用餐管理</span>
            </template>

            <el-menu-item v-if="menu.indexOf('27') !=-1" index="/reporting" @click="ssy('/reporting')" >报餐管理</el-menu-item>
            <el-menu-item  v-if="menu.indexOf('28') !=-1" index="/mealrecord" @click="ssy('/mealrecord')" >用餐记录</el-menu-item>
            <el-menu-item  v-if="menu.indexOf('29') !=-1" index="/mealstas" @click="ssy('/mealstas')" >用餐统计</el-menu-item>
            <el-menu-item v-if="menu.indexOf('30') !=-1" index="/tableware" @click="ssy('/tableware')" >餐具管理</el-menu-item>
            <el-menu-item v-if="menu.indexOf('31')!=-1 && UserInfo.is_dinner_sys==1" index="/mealdecl" @click="ssy('/mealdecl')" >报餐结算</el-menu-item>
           
        </el-submenu>

        <el-submenu index="4" v-if="(menu.indexOf('9') !=-1 || menu.indexOf('10') !=-1) && UserInfo.is_dinner_sys==0" >
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span>{{$t('navLeft.fkgl')}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/visitormanagement" @click="ssy('/visitormanagement')" v-if="menu.indexOf('9') !=-1">{{$t('navLeft.fkdj')}}</el-menu-item>
            <el-menu-item index="/appointment" @click="ssy('/appointment')" v-if="menu.indexOf('10') !=-1">{{$t('navLeft.yysh')}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-submenu index="5"
          v-if="(menu.indexOf('11') !=-1 || menu.indexOf('12') !=-1 || menu.indexOf('13') !=-1 || menu.indexOf('14') !=-1 || menu.indexOf('15') !=-1 || menu.indexOf('16') !=-1 || menu.indexOf('17') !=-1 || menu.indexOf('18') !=-1)&& UserInfo.is_dinner_sys==0"
        >
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span>{{$t('navLeft.kqgl')}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/datasources" @click="ssy('/datasources')" v-if="menu.indexOf('11') !=-1">{{$t('navLeft.sjly')}}</el-menu-item>
            <el-menu-item index="/attparam" @click="ssy('/attparam')" v-if="menu.indexOf('12') !=-1">{{$t('navLeft.kqsz')}}</el-menu-item>
            <el-menu-item index="/attenshift" @click="ssy('/attenshift')" v-if="menu.indexOf('13') !=-1">{{$t('navLeft.kqbc')}}</el-menu-item>
            <el-menu-item index="/scheduling" @click="ssy('/scheduling')" v-if="menu.indexOf('14') !=-1">{{$t('navLeft.kqpb')}}</el-menu-item>
            <el-menu-item index="/holiday" @click="ssy('/holiday')" v-if="menu.indexOf('15') !=-1">{{$t('navLeft.jrgl')}}</el-menu-item>
            <el-menu-item index="/leaveman" @click="ssy('/leaveman')" v-if="menu.indexOf('16') !=-1">{{$t('navLeft.qjgl')}}</el-menu-item>
            <el-menu-item index="/manual" @click="ssy('/manual')" v-if="menu.indexOf('17') !=-1">{{$t('navLeft.sgqd')}}</el-menu-item>
            <el-menu-item index="/timetracking" @click="ssy('/timetracking')" v-if="menu.indexOf('18') !=-1">{{$t('navLeft.kqcl')}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

         <el-submenu index="6"  
          v-if="(menu.indexOf('19') !=-1 || menu.indexOf('20') !=-1 || menu.indexOf('21') !=-1 || menu.indexOf('22') !=-1 ||menu.indexOf('23') !=-1 || menu.indexOf('24') !=-1)&& UserInfo.is_dinner_sys==0" >
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span>{{$t('navLeft.bbsj')}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/perecord" @click="ssy('/perecord')" v-if="menu.indexOf('19') !=-1">{{$t('navLeft.txjl')}}</el-menu-item>
            <el-menu-item index="/attendance" @click="ssy('/attendance')" v-if="menu.indexOf('20') !=-1">{{$t('navLeft.kqrb')}}</el-menu-item>
            <el-menu-item index="/monthlyreport" @click="ssy('/monthlyreport')" v-if="menu.indexOf('21') !=-1">{{$t('navLeft.kqyb')}}</el-menu-item>
            <el-menu-item index="/record" @click="ssy('/record')" v-if="menu.indexOf('22') !=-1">{{$t('navLeft.fkjl')}}</el-menu-item>
            <el-menu-item index="/illegal" @click="ssy('/illegal')" v-if="menu.indexOf('23') !=-1">{{$t('navLeft.ffjl')}}</el-menu-item>
            <el-menu-item index="/camerecord" @click="ssy('/camerecord')" v-if="menu.indexOf('24') !=-1">{{$t('navLeft.zpjl')}}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <!-- <el-submenu index="8" v-if="this.$i18n.locale == 'cn'&& UserInfo.is_dinner_sys==0">
            <template slot="title">
              <i class="el-icon-menu"></i>
              <span>智能IPC</span>
            </template>

            <el-menu-item  v-if="menu.indexOf('27') !=-1" index="/recordproc" @click="ssy('/recordproc')" >报警记录处理</el-menu-item>
            <el-menu-item  v-if="menu.indexOf('28') !=-1" index="/alarm" @click="ssy('/alarm')" >报警处理记录</el-menu-item>
            <el-menu-item  v-if="menu.indexOf('29') !=-1" index="/alarmpush" @click="ssy('/alarmpush')" >设置报警推送</el-menu-item>
            <el-menu-item v-if="menu.indexOf('30') !=-1" index="/pusalrec" @click="ssy('/pusalrec')" >推送报警记录</el-menu-item>
            <el-menu-item v-if="menu.indexOf('31')!=-1" index="/reatimevid" @click="ssy('/reatimevid')" >实时视频查看</el-menu-item>
            <el-menu-item v-if="menu.indexOf('32') !=-1" index="/videorecord" @click="ssy('/videorecord')" >视频查看记录</el-menu-item>
             
        </el-submenu> -->


         <!-- <el-submenu index="10">
           <template slot="title">
              <i class="el-icon-menu"></i>
              <span>{{$t('navLeft.zncw')}}</span>
            </template> -->
            <!-- <el-menu-item  v-if="menu.indexOf('26') !=-1" index="/realtime" @click="ssy('/realtime')" >{{$t('navLeft.sscw')}}</el-menu-item> -->
            <!-- <el-menu-item  v-if="menu.indexOf('27') !=-1" index="/parking" @click="ssy('/parking')" >车位设备</el-menu-item> -->
             <!-- <el-menu-item  v-if="menu.indexOf('25') !=-1" index="/carmang" @click="ssy('/carmang')" >{{$t('navLeft.cwgl')}}</el-menu-item> -->
            <!-- <el-menu-item  v-if="menu.indexOf('28') !=-1" index="/parkingdata" @click="ssy('/parkingdata')" >停车数据</el-menu-item> -->
            <!-- <el-menu-item v-if="menu.indexOf('29') !=-1" index="/pedestrian" @click="ssy('/pedestrian')" >人车信息</el-menu-item> -->
            <!-- <el-menu-item v-if="menu.indexOf('30')!=-1" index="/searecar" @click="ssy('/searecar')" >寻车记录</el-menu-item> -->
            <!-- <el-menu-item v-if="menu.indexOf('31') !=-1" index="/parkingphotos" @click="ssy('/parkingphotos')" >停车照片</el-menu-item> -->
            <!-- <el-menu-item v-if="menu.indexOf('32')!=-1" index="/spacemark" @click="ssy('/spacemark')" >车位标注</el-menu-item> -->
            <!-- <el-menu-item v-if="menu.indexOf('24') !=-1" index="/parkininfo" @click="ssy('/parkininfo')" >{{$t('navLeft.ccxx')}}</el-menu-item> -->
        <!-- </el-submenu> -->
        
        


         <el-submenu index="7" v-if="(menu.indexOf('25') !=-1 || menu.indexOf('26') !=-1) && UserInfo.is_dinner_sys==0">
          <template slot="title">
            <i class="el-icon-menu"></i>
            <span>{{$t('navLeft.xtsz')}}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item index="/administrator" @click="ssy('/administrator')" v-if="menu.indexOf('25') !=-1">{{$t('navLeft.glysz')}}</el-menu-item>
            <el-menu-item index="/traffic" @click="ssy('/traffic')" v-if="menu.indexOf('26') !=-1">{{$t('navLeft.llksz')}}</el-menu-item>
			<el-menu-item index="/superAdmin" @click="ssy('/superAdmin')">超级管理员</el-menu-item>
          </el-menu-item-group>
        </el-submenu>


  </el-menu>
</div>
 
</template>
<script>
import '@/icons/zhy.svg' 
import { mapState } from 'vuex'
export default {
  
  data () {
    return {
      UserInfo:JSON.parse(window.localStorage.getItem('user')),
      menu :[],
      msg : 'navLeft.msg',
      navmenlist: [
        {
          authname: 'navLeft.qygl',
          id: '1',
          childrens: [{
            id: '1-1',
            path: '/information',
            authname: 'navLeft.qyzl',
            
          },
          {
            path: '/structure',
            id: '1-2',
            authname: 'navLeft.qyjg'
          }]
        },
        {
          authname: 'navLeft.sbgl',
          id: '2',
          childrens: [{
            path: '/devicemanagem',
            id: '2-1',
            authname: 'navLeft.sbgl'
          },
          {
            path: '/statusquery',
            id: '2-2',
            authname: 'navLeft.ztcx'
          }]
        },
         {
          authname: 'navLeft.yggl',
          id: '5',
          childrens: [{
            path: '/perinformation',
            id: '5-1',
            authname: 'navLeft.ryzlH'
          },
          {
            path: '/selfaudit',
            id: '5-2',
            authname: 'navLeft.zzsh'
          },
          {
            path: '/authmanagem',
            id: '5-3',
            authname: 'navLeft.qxgl'
          },
          // {
          //   path: '/timanage',
          //   id: '5-4',
          //   authname: 'navLeft.sjgl'
          // },
          // {
          //   path: '/replacement',
          //   id: '5-5',
          //   authname: '坏卡补卡'
          // }
          ]
        },
        {
          authname: 'navLeft.fkgl',
          id: '3',
          childrens: [{
            path: '/visitormanagement',
            id: '3-1',
            authname: 'navLeft.fkdj'
          },
          {
            path: '/appointment',
            id: '3-2',
            authname: 'navLeft.yysh'
          }]
        },
        {
          authname: 'navLeft.kqgl',
          id: '4',
          childrens: [{
            path: '/datasources',
            id: '4-1',
            authname: 'navLeft.sjly'
          },
          {
            path: '/attparam',
            id: '4-2',
            authname: 'navLeft.kqcs'
          }
          ,
          {
            path: '/attenshift',
            id: '4-3',
            authname: 'navLeft.kqbc'
          },
          {
            path: '/scheduling',
            id: '4-4',
            authname: 'navLeft.kqpb'
          },
          {
            path: '/holiday',
            id: '4-5',
            authname: 'navLeft.jrgl'
          },
          {
            path: '/leaveman',
            id: '4-6',
            authname: 'navLeft.qjgl'
          },
          {
            path: '/manual',
            id: '4-7',
            authname: 'navLeft.sgqd'
          },
          {
            path: '/timetracking',
            id: '4-8',
            authname: 'navLeft.kqcl'
          },
          
          ]
        },
       
        {
          authname: 'navLeft.bbsj',
          id: '6',
          childrens: [{
            path: '/perecord',
            id: '6-1',
            authname: 'navLeft.txjl'
          },
          {
            path: '/attendance',
            id: '6-2',
            authname: 'navLeft.kqrb'
          },
          {
            path: '/monthlyreport',
            id: '6-3',
            authname: 'navLeft.kqyb'
          },{
            path: '/record',
            id: '6-4',
            authname: 'navLeft.fkjl'
          },{
            path: '/illegal',
            id: '6-5',
            authname: 'navLeft.ffjl'
          },{
            path: '/camerecord',
            id: '6-6',
            authname: 'navLeft.zpjl'
          },
          
          ]
        },
        {
          authname: 'navLeft.xtsz',
          id: '7',
          childrens: [{
            path: '/administrator',
            id: '7-1',
            authname: 'navLeft.glysz'
          },
          {
            path: '/traffic',
            id: '7-2',
            authname: 'navLeft.llksz'
          }
          ]
        },
        // {
        //   authname: '智能IPC',
        //   id: '8',
        //   childrens: [{
        //     path: '/recordproc',
        //     id: '8-1',
        //     authname: '报警记录处理'
        //   },
        //   {
        //     path: '/alarm',
        //     id: '8-2',
        //     authname: '报警处理记录'
        //   }
        //   ]
        // },
       
      ],
      lang:""
    }
  },
  computed:{

  },
  props: ['is-collapse'],
  watch:{
    "lang":function(oval,nval){
      if(oval !=nval){
        this.$i18n.locale = this.lang
      }
    },
    '$route' (to, from) { //监听路由是否变化
      if(to.path == from.path){
        this.$router.go(0);
      }
    }
  },
  computed:{
    ...mapState({
      langvalue: state=>state.lang.langvalue,
    })
  },
  mounted(){
    if(this.UserInfo.is_dinner_sys == 'undefined'){
      this.UserInfo.is_dinner_sys = 0
    }
    this.lang =this.langvalue
    this.menu=JSON.parse(window.localStorage.getItem('user')).menu.split(',')
  },
  methods: {
    ssy(index){
      if(index==this.$route.path){
        this.$router.go(0)
      }
    },
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },
    clickMenu(value){
      this.$store.commit('selectMenu', this.$t(value.authname))
      window.localStorage.setItem('currentMenu', this.$t(value.authname))
    },
    clickfaMenu(value){
      // console.log('2',value)
      this.$store.commit('faselectMenu', this.$t(value.authname))
      window.localStorage.setItem('fathcurrentMenu', this.$t(value.authname))
    }
  }
}
</script>
<style lang="less" scoped>
.aios{
  
}
.el-menu-item.is-active {
  background-color: #1890ff !important;
  border-radius: 3px;
}
.aios /deep/ .el-menu-item-group .el-menu-item-group__title{
    padding: 0px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.asdetitle{
  height: 60px;
  color: #fff;
  text-align: center;
  line-height: 60px;
  font-family: "宋体";
  border-bottom: 1px solid hsla(0,0%,93.3%,.1);
  display: flex;
  .titlecontent{
    width: 80%;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
    margin: 0 auto;
    background-color:rgba(255, 255, 255, 0.2);
    margin-top: 8px;
    a {
      span{
        color: #fff;
      }
      .iii{
        color:#fff;
            width: 25px;
          height: 25px;
          margin-right: 4px;
      }
    }
  }
}
 .aios /deep/ .el-submenu  {
    border-bottom: 1px solid hsla(0,0%,93.3%,.1);
}
.aios /deep/ .el-submenu .el-menu-item{
  height: 45px;
  line-height: 45px;
  padding-left: 50px !important;
}
.el-aside{
     overflow: 1;
}
</style>
