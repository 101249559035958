module.exports={
    // 头部菜单
    header_meun:{
        logout: "退出"
    },
    // 左侧导航
    adminhou:'门禁管理系统',
    adminname:'系统',
    navLeft:{
        qygl:"业主资料",
        qyzl:"企业资料",
        qyjg:'企业架构',
        add:'新增',
		addR:'新增',
		addU:'新增',
        sbgl:'设备管理',
		sbglH:'设备管理',
		mjjz:'门禁机组',
        ztcx:'状态查询',
        yggl:'业主管理',
		ygglH:'业主管理',
        ryzl:'人员资料',
		ryzlH:'人员资料',
        zzsh:'自助审核',
        qxgl:'权限管理',
        sjgl:'时段管理',
        fkgl:'访客管理',
        fkdj:'访客登记',
        yysh:'预约审核',
        kqgl:'考勤管理',
        sjly:'数据来源',
        kqcs:'考勤参数',
		kqsz:'考勤参数',
        kqbc:'考勤班次',
        kqpb:'考勤排班',
        jrgl:'假日管理',
        qjgl:'请假管理',
        sgqd:'手工签到',
        kqcl:'考勤处理',
        bbsj:'报表数据',
        txjl:'通行记录',
        kqrb:'考勤日报',
        kqyb:'考勤月报',
        fkjl:'访客记录',
        ffjl:'非法记录',
        zpjl:'照片记录',
        xtsz:'系统设置',
        glysz:'管理员设置',
        llksz:'流量卡查询',
        bcgl:'班次管理',
        hkbk:'坏卡补卡',
        qdgl:'签到管理',
        sjtb:"数据同步",
		zncw:"智能车位",
		sscw:"实时车位",
		cwgl:"车位管理",
		ccxx:"车场信息",
		tjkqcl:'添加'
    },
	LaterOhther:{
		bjjlcl:'报警记录处理',
		znIPC:'智能IPC',
		bjcljl:'报警处理记录',
		szbjts:'设置报警推送',
		tsbjjl:'推送报警记录',
		ssspck:'实时视频查看',
		spckjl:'视频查看记录'
	},
    tablename:{
        scewm:'生成二维码',
        xgqyzlH:'修改企业资料',
        gsmc:'公司名称',
		gsmcH:'公司名称',
        lxdh:'联系电话',
        yxdz:'邮箱地址',
        szsf:'所在省份',
        szcs:'所在城市',
        xxdz:'详细地址',
        qd:'确 定',
        qymc:'企业名称',
        cz:'操作',
        zj:"增加",
        sc:'删除',
        bj:'编辑',
		bjH:'编辑',
		jsj:'加时间',
		sjqh:'手机区号'
    },
    devtable:{
		
		bmqxgl:'部门权限管理',
		qsrzjmc:'请输入机组名称',
		qszsb:'请选择设备',
		jzid:'机组ID',
		lyh:'蓝牙号',
		mjzjbh:'门禁机组编号',
		mjjzmc:'门禁机组名称',
		qrsqsz:'确认授权设置',
		zdsqglan:'自动授权管理按钮',
		zdsqjzsj:'自动授权截止时间',
		zdsqkl:'自动授权口令',
		qsr:'请输入',
		tjH:'添加',
		rq:'日期',
        rqgs:'日期格式',
        moth:'月',
        data:'日',
        sbh:'设备号',
        sbbzx:'设备不在线',
		czxcxdlmm:'重置小程序登录密码',
        zzmm:'重置密码',
        zzcg:'重置成功',
        zzsb:'重置失败',
        qc:'清除',
        gx:'更新',
        km:'开门',
        wh:'维护',
        xg:'修改',
        cq:'重启',

        sb:'设备ID',
		sbH:'设备管理',
        azwz:'安装位置',
        kmzt:'开门状态',
        kmms:'开门模式',
		kmmsI:'开门模式',
        sbxlh:'设备序列号',
        zxzt:'在线状态',
        sblx:'设备类型',
        zcj:'注册机',
		llk:"流量卡",

        ysms:'延时模式',
        ckms:'常开模式',
        cbms:'常闭模式',
        frlj:'非人脸机',
        rlj:'人脸机',
        zx:'在线',
        lx:'离线',
        s:'是',
        f:'否',
        j:'进',
        c:'出',
        kmys:'开门延时',

        sbmc:'设备名称',
		sbxlhD:'设备序列号',
        sbzt:'设备状态',
        wxz:'未选择',
        cx:'查询',

        rlsl:'人脸数量',
        mksl:'门卡数量',


        zcid:'注册id',
        xm:'姓名',
        gh:'工号',
		ghH:'工号',
		ghHH:'工号',
        qybm:'企业部门',
        xb:'性别',
        sjhm:'手机号码',
		sfrsjhmS:'手机号码',
        zjhm:'证件号码',
		zjhmH:'证件号码',
        kh:'卡号',
        rxzp:'人像照片',
        yl:'预览',
        male:'男',
        female:'女',

        shzt:'审核状态',
        rzlx:'认证类型',
        shr:'审核人',
        shsj:"审核时间",
        zczt:'注册状态',
        ysp:'已审批',
        wsp:'未审批',

        wzc:'未注册',
		qbmr:'全部(默认)',
        yzc:'已注册',
        bfzc:'部分注册',
        zcsb:'注册失败',
        yg:"员工",
        yl:'预览',
        bm:'部门',
        sh:'审核',
		sjhmN:'手机号码',
        plsh:'批量审核',
        qbsh:'全部审核',
        rylx:'人员类型',
		rylxS:'人员类型',
        tbsj:'同步时间',
        tbzt:'同步状态',
        qxck:'权限查看',
        fk:'访客',
        wtb:'未同步',
        ry:'人员',
        lwtb:'人脸未同步',
        kwtb:'卡未同步',
        kmmmwtb:'开门密码未同步',
        ytb:"已同步",
        bzx:'不在线',
        ymj:'云门禁',

        sdh:'时段号',

        qsrq:"起始日期",
        jzrq:'截止日期',
        sjms:'时间描述',
        xygljsd:'下一个链接时段',
        cj:'创建',
        qdjcx:'请点击查询',

        lfrxm:'来访人姓名'   ,
        bfrbm:'被访人部门'    ,
		bfrbmT:'被访人部门',
        bfr:'被访人',
		bfrr:'被访人',
        bfrsjhm:"被访人手机号码",
        lfrzp:"来访人照片",
		lfrzpD:"来访人照片",
        lfsj:"来访时间",
        lfcph:"来访车牌号",
        tb:'同步',

        tjsbkq:'添加考勤设备',
		tjsbkqH:'添加考勤设备',

        mc:'名称',
        dklx:'打卡类型',
        sbsjd:'上班时间段',

        gzmc:'规则名称',
		gzmcH:'规则名称',
        bcjgz:"班次及规则",
        yyfw:'应用范围',
        xh:'序号',

        jjrmc:'节假日名称',
        ksrq:'开始日期:',
        xzrq:"选择日期",
        jsrq:'结束日期',
		ksrqH:'开始日期:',
		jsrqH:'结束日期',
        ms:'描述',
        qjlx:'请假类型',
		qjlxH:'请假类型',

        ygid:'员工ID',
        ygxm:'员工姓名',
        qdsj:'签到时间',
        bz:'备注',
        bc:'班次',

        tgsj:'通过时间',
        zt:'状态',
        kmlx:'开门类型',

        skrq:'刷卡日期',
        cdfz:'迟到(次)',
        ztfz:'早退(分钟)',
        jbfz:'加班(分钟)',
        sk:'刷卡',
        yf:'月份',
        yqts:'应勤天',
        sqts:'实勤天',
        cdcs:'迟到(次)',
        ztcs:'早退(次)',
        yskcs:'应刷卡(次)',
        wskcs:'未刷卡(次)',
        qjt:"请假(天)",
        cct:'出差(天)',
        jbxs:'加班(时)',
        kgt:'旷工(天)',


        sfr:'受访人',
		kssjS:'开始时间',
		jssjS:'结束时间',
        sfrsjhm:'受访人手机号码',
		xzrqsj:'选择日期时间',

        khkm:'卡号开门',
        glyid:'管理员ID',
        yhm:'用户名',
        mm:"密码",
        jsmc:'角色名称',
        ymqx:'页面权限',
        llkmc:'流量卡名称',
        llkxh:'流量卡序号',
        qysj:'启用时间',
        zll:'总流量',
        syll:'剩余流量',
        czsj:'充值时间',
        czje:'充值金额',
		pltb:'批量同步',

        tjzbm:'添加子部门',
        bmmc:'部门名称',
		zbmmc:'子部门名称',
        bjbm:'编辑部门',
        tsxx:'提示信息',

        yhmsryw:'用户名输入有误!',
        yhmmsryw:'用户密码输入有误!',
        sccg:'删除成功!',
        scsbmyqx:'删除失败:没有权限!',
        scsb:'删除失败!',
		bdcg:"绑定成功",
		bdsb:"绑定失败",
		zbdllk:'未找到流量卡',

        sfzcj:'是否注册机',
		sblxH:'设备类型',
        bmqx:'部门权限',
        kzcs:'扩展参数',
        xzsb:'新增设备',
        htjc:'活体检测',
        sssc:'实时上传',
        gbxxk:'关闭信息框',
        bgcl:'补光常亮',
        bjlzp:'不记录照片',
        bsczp:'不上传照片',
        ewmsb:'二维码识别',
        sbms:'识别模式',
        dr:'单人',
        ddr:'多人',
        kq:'开启',
        gb:'关闭',

        ylrxzp:'预览人像照片',
        xgryzl:'修改人员资料',
        scsbryzlbcz:'删除失败:人员资料不存在!',
        tjryzl:'添加人员资料',
        dr:'导入',
        dc:'导出',
		drmb:'导入模板',
		drsb:'导入失败',
		hss:'行数',
		yy:'原因',

        zjlx:'证件类型',
        sfz:'身份证',
        hz:'护照',
        jzz:'居住证',
        jsz:'驾驶证',
        jg:'籍贯',
        hj:"户籍",
        csrq:'出生日期',
        mz:'民族',
        kmmm:'开门密码',
        yxqks:'有效期开始',
        yxqjs:'有效期结束',
        zpcjyq:'照片采集要求：',
        zmrlmgbsz:'正面人脸免冠半身照',
        rlhmzb:'人脸画面占比2/3以上',
        sbxlhhwz:'设备序列号或者位置',

        tbcg:'同步成功',
        tsb:'同步失败',
        cjsjd:'创建时间段',
        sjmx:'时间描叙',
        bsmxzd:'[0表示不限，最大31]',
        xygljsd:"下一个链接时段",
        yxxqxx:'有效星期选项',
        mryxsq:'每日有效时区',
        sq:'时区',
        rysjd:'任意时间点',
        xckz:'限次控制',
        dyxc:'单月限次',
        dtxc:"单天限次",
        sqyxc:'时区1限次',
        sqexc:'时区2限次',
        sqsxc:'时区3限次',
        qxzcs:'请选择次数',
        xqy:'星期一',
        xqe:'星期二',
        xqs:'星期三',
        xqss:'星期四',
        xqw:"星期五",
        xql:"星期六",
        xqq:'星期天',

        tjbc:'添加班次',
        bcmc:'班次名称：',
        gzsc:"工作时长：",
        sbbxs:"上班时间8小时",
        gzsj:'工作时间',
        qssj:"起始时间",
        jssj:'结束时间',
        xxsj:'休息时间',
        xgbc:"修改班次",
        slbb:"示列：白班",
        tjbbgz:"添加排班规则",
        gzmc:"规则名称：",
        xzbc:"选择班次：",
        qxz:"请选择",
        gzr:"工作日：",
        tj:"添加",
        yxyg:"已选员工",
		yxygH:"已选员工",
        xgbbgz:'修改排班规则',
        tjjr:'添加节假日',
        xgjjr:"修改节假日",
        xgcg:'修改成功',
        xgsb:'修改失败',
        qjlb:'请假类别：',
        mx:"描叙：",
        sjj:'事假',
        bjj:"病假",
        cch:'出差',
        cjj:"产假",
        njj:'年假',
        hsjq:'婚丧嫁娶',
        qtt:'其他',
        tjsgqd:'添加手工签到',
        qdrq:'签到日期：',
        xgqd:'修改签到',
        xkkm:'门卡开门',
        yckm:"远程开门",
        lykm:'蓝牙开门',
        mmkm:'密码开门',
        zwkm:"指纹开门",
        rlkm:'人脸开门',

        scgly:'删除管理员',

        tjjsgly:'添加角色管理员',
        yhjs:'用户角色',
        rsjl:'人事经理',
        czy:"操作员",
        tjymqx:'添加角色页面权限',
        yhmm:"用户密码",
        bcz:'保存',
        xgjsgly:'修改角色管理员',
        czz:'充值',
        qrtcm:'确认退出吗？',
        tcts:'退出提示',
        qdd:'确定',
        qxx:'取消',
        yhdl:'用户登录',

        sjhmsryw:'手机号码输入有误',

        kjfs:'快捷方式',
        dbsx:'待办事项',
        glxtczsp:'管理系统操作视频',
        dshry:'待审核人员',
        sjgll:'数据概览：',
        yllfryzp:'预览来访人员照片',
        djj:"登记",
        zpdj:'照片登记',
        pzz:'拍照',
        zpxz:'照片选择',
        dksxt:'打开摄像头',
        gbsxt:'关闭摄像头',
        lfxxdj:'来访信息登记',
        lfrr:'来访人',
        dww:'单    位',
        dhh:"电    话",
        cph:'车牌号',
        sxry:'随行人员',
        bfr:'被访问人',
        bfsy:'被访事由',
        sbsq:'设备授权',
        xzyg:"选择员工",
        kssj:'开始时间',
        lfr:'来访人',
        azdz:'安装地址',
        szkqcs:"设置考勤参数",

        cddt:'迟到',
        ynbscd:'以内不算迟到',
        ysskg:'以上算旷工',
        tq:'提前',
        ynbsct:'以内不算早退',
        bk:'补卡',
        cmyyx:'次每月有效',
        gzsjjs:'工作时间结束',
        hsksjb:'后刷卡算加班',
        zwzzyxsksj:'最早最晚有效刷卡时间',
        bxcs:'不限次数',
        wsz:'无设置',
        fz:'分钟',
        xs:'小时',
        t:'天',
        cs:'次',
        mt:'每天',
        fwyh:'访问用户',
        ggwhsb:"更改维护设备",
        ysbxlh:'原设备序列号',
        xshxlh:'新设备序列号',
        fzqx:"复制权限",
        thsb:"替换设备",
        sbb:'同 步',
        fzcg:'复制成功!',
        fzsbybycz:'复制失败:设备已存在!',
        fzsb:'复制失败!',
        thcg:'替换成功!',
        thsbsbycz:'替换失败:设备已存在!',
        thsbb:"替换失败!",
        tbsbmyqx:'同步失败:没有权限!',
        miao:'秒',
        sbbcz:'设备不存在',
        qxcxsb:'请先查询设备!',
        qxxzbm:'请选择部门',
        qtxazwz:'请填写安装位置',
        sbtjcg:'设备添加成功!',
        sbtjsb:'设备添加失败',
        sbxgcg:'设备修改成功!',
        sbycz:'设备已存在:',
        ytjlgsb:'已添加了该设备',
        scsbt:'删除设备',
        xgsbt:'修改设备',
       gxsbsb :'更新设备失败!',
       cwjxz:'从文件选择',
       tpcj:'图片剪裁',
       tjcg:'添加成功',
       khycz:'卡号已存在',
       lgsjdbnxt:'两个时段不能相同',
       ghycz:'工号已存在',
       sjycz:'数据已存在',
       qsrmz:'请输入名字',
       qsrsjhm:'请输入手机号码',
       qsrgh:'请输入工号',
       qsrzjh:'请输入证件号',
       ckyg:'查看员工',
       qx:"权限",
       cksb:'查看设备',
       scxzqx:'删除选中权限',
       scxzsbqx:'删除选中设备权限',
       sbazwz:'设备安装位置',
       jclx:'进出类型',
       sbt:'设备',
       sjd:'时间段',
       yxsb:'已选设备',
       bccg:'保存成功',
       bcsb:'保存失败',
       
       lfrxmbnwk:'来访人姓名不能为空!',
       bfrbnwk:'被访问人不能为空!',
       qxzsb:'请先选择设备!',
       fkzccg:'访客注册成功！',
       fkzcsb:'访客注册失败',
       shcg:'审核成功',
       zcrlsbbzx:'注册人脸设备不在线',
       shsb:'审核失败',
       qxzxyshdfk:'请先选择需要审核的访客',
       wsh:'未审核',
       ysh:'已审核',
       sgqdz:'手工签到中',
       qsrygxm:'请输入员工姓名!',
       qxzyg:'请选择员工!',
       qszrq:'请设置日期!',
       qdtjcg:'签到添加成功',
       qdtjsb:'签到添加失败',
       qdxgcg:'签到修改成功',
       qdxgsb:"签到修改失败",
       kqclz:'考勤处理中',
       scsbglybcz:'删除失败:管理员不存在!',
       scsbmyyhqxhzhcw:'删除失败:没有权限或账号错误!',
       gglymqxtj:'该管理员没权限添加',
       tjsbxmycz:'添加失败:姓名已存在!',
       xgsbxmycz:'修改失败:姓名已存在!',
       tjsb:'添加失败',
       szcg:'设置成功！',
       szsb:'设置失败！',
       clcg:'处理成功！',
       clsb:'处理失败！',
       rybcz:'人员不存在',
       scsbfkbcz:'删除失败:访客不存在!',
       zcsbbcz:'注册设备不存在',
       zcrlbcz:'注册人脸不存在',
       tjsbbmycz:'添加失败:部门已存在!',
        kqcl:'考勤处理',
        lfrm:'来访人名',
        xtgly:'系统管理员',
        tjqjcc:'添加请假出差',
        xgqjcc:'修改请假出差',
        
        hs:'海思',
        xc:'星宸',
        changj:'厂家'
    },
    // 手机号国家区
    zgdl:'中国大陆',
    zgxg:'中国香港',
    zgam:'中国澳门',
    zgtw:'中国台湾',
    afh:"阿富汗",
    ade:'安道尔',
    alq:'阿联酋',
    am:'阿曼',
    agt:'阿根廷',
    aejly:'阿尔及利亚',
    ymny:'亚美尼亚',
    alb:'阿鲁巴',
    adl:"奥地利",
    asbj:'阿塞拜疆',

    bhm:'巴哈马',
    bl:'巴林',
    bbds:'巴巴多斯',
    blst:'巴勒斯坦',
    bjst:'巴基斯坦',
    bels:'白俄罗斯',
    bls:'比利时',
    cxx:'朝鲜',
    cdjny:'赤道几内亚',
    dg:'德国',
    dm:'丹麦',
    jbt:'吉布提',
    dmnjghg:'多米尼加共和国',
    els:'俄罗斯',
    egde:'厄瓜多尔',
    aj:'埃及',
    aseby:'埃塞俄比亚',

    fg:'法国',
    fl:'芬兰',
    flb:'菲律宾',
    fdg:'梵蒂冈',

    gby:'冈比亚',
    ggghg:'刚果共和国',
    glby:'哥伦比亚',
    gljy:'格鲁吉亚',
    gba:'古巴',
    hg:'韩国',
    hl:'荷兰',
    hd:'海地',

    jbz:'柬埔寨',
    jlbs:'基里巴斯',
    jny:'几内亚',
    jbbw:"津巴布韦",
    kml:'喀麦隆',
    kmqt:'开曼群岛',
    kny:'肯尼亚',
    kwt:'科威特',
    kldy:'克罗地亚',
    kte:'卡塔尔',
    lys:'老挝',
    lby:'利比亚',
    lwd:'卢旺达',
    mg:'美国',
    mlxy:'马来西亚',
    mxg:'墨西哥',
    md:'缅甸',
    mlg:"摩洛哥",
    nf:'南非',
    nbe:'尼泊尔',
    nrly:'尼日利亚',
    nw:'挪威',
    plqd:'帕劳群岛',
    pty:'葡萄牙',
    rb:'日本',
    rd:'瑞典',
    rs:'瑞士',
    stalb:'沙特阿拉伯',
    sd:'苏丹',
    slxy:'圣卢西亚',
    tgs:'泰国',
    tjs:'汤加',
    teq:'土耳其',
    tns:"突尼斯",
    wnrl:'委内瑞拉',
    wl:'文莱',
    wkl:'乌克兰',
    xxl:'新西兰',
    xjb:'新加坡',
    xby:'西班牙',
    xl:'希腊',
    xly:'叙利亚',
    xyl:'匈牙利',
    ydl:'意大利',
    yg:'英国',
    ydnxy:'印度尼西亚',
    yd:'印度',
    yn:'越南',
    yl:'伊朗',
    ylk:'伊拉克',
    ysl:'以色列',
    ym:'也门',
    zl:'智利',
    zfghg:'中非共和国',

    yhmbcz:'用户名不存在',
    yhmycz:'手机号码或用户名已存在',
    qtxyhm:'请填写用户名',
    hqsb:'获取失败',
    yhmbnwk:'用户名不能为空',
    mmbnwk:'密码不能为空',
    gsmcbnwk:'公司名称不能为空',
    sjhmbnwk:"手机号码不能为空",
    yzmbnwk:'验证码不能为空'

}