import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout'
import Home from '@/views/home'
import communitylist from '@/views/login/communitylist'
Vue.use(VueRouter)

const routes = [
  { path: '*', redirect: '/home' },
  {
    path: '/login',
    name: 'login',
    component: resolve => require(['@/views/login'], resolve)
  
  },
  {
    path: '/communitylist',
    name: 'communitylist',
    component: communitylist
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home
      },
	  
      {
        path: '/structure',
        name: 'Structure',
        component: resolve => require(['@/views/busmana/structure'], resolve),
        meta: {
          requireAuth: true,
          module: 'navLeft.qygl',
          title:'navLeft.qyjg'
        }
      },
      {
        path: '/information',
        name: 'Information',
        component: resolve => require(['@/views/busmana/information'], resolve),
        meta: {
          module: "navLeft.qygl",
          title:'navLeft.qyzl'
        }
      },
      {
        path: '/devicemanagem',
        name: 'Contentome',
        component: resolve => require(['@/views/devicemanagem/index'], resolve),
        meta: {
          module: "navLeft.sbgl",
          title:'navLeft.sbgl'
        }
      },
			{
			  path: '/accessControl',
			  name: 'Accesscontrol',
			  component: resolve => require(['@/views/devicemanagem/accessControl'], resolve),
			  meta: {
			    module: "navLeft.sbgl",
			    title:'navLeft.mjjz'
			  }
			},
      {
        path: '/statusquery',
        name: 'Statusquery',
        component: resolve => require(['@/views/devicemanagem/statusquery'], resolve),
        meta: {
          module: "navLeft.sbgl",
          title:'navLeft.ztcx'
        }
      },
      {
        path: '/visitormanagement',
        name: 'Visitormanagement',
        component: resolve => require(['@/views/visitormanagement/index'], resolve),
        meta: {
          module: "navLeft.fkgl",
          title:'navLeft.fkdj'
        }
      },
      {
        path: '/appointment',
        name: 'Appointment',
        component: resolve => require(['@/views/visitormanagement/appointment'], resolve),
        meta: {
          module: "navLeft.fkgl",
          title:'navLeft.yysh'
        }
      },
      {
        path: '/datasources',
        name: 'Datasources',
        component: resolve => require(['@/views/workattendance/index'], resolve),
        meta: {
          module: "navLeft.kqgl",
          title:'navLeft.sjly'
        }
      },
      {
        path: '/attparam',
        name: 'Attparam',
        component: resolve => require(['@/views/workattendance/attparam'], resolve),
        meta: {
          module: "navLeft.kqgl",
          title:'navLeft.kqcs'
        }
      },
      {
        path: '/attenshift',
        name: 'Attenshift',
        component: resolve => require(['@/views/workattendance/attenshift'], resolve),
        meta: {
          module: "navLeft.kqgl",
          title:'navLeft.kqbc'
        }
      },
      {
        path: '/scheduling',
        name: 'Scheduling',
        component: resolve => require(['@/views/workattendance/scheduling'], resolve),
        meta: {
          module: "navLeft.kqgl",
          title:'navLeft.kqpb'
        }
      },
      {
        path: '/holiday',
        name: 'Holiday',
        component: resolve => require(['@/views/workattendance/holiday'], resolve),
        meta: {
          module: "navLeft.kqgl",
          title:'navLeft.jrgl'
        }
      },
      {
        path: '/leaveman',
        name: 'Leaveman',
        component: resolve => require(['@/views/workattendance/leaveman'], resolve),
        meta: {
          module: "navLeft.kqgl",
          title:'navLeft.qjgl'
        }
      },
      {
        path: '/manual',
        name: 'Manual',
        component: resolve => require(['@/views/workattendance/manual'], resolve),
        meta: {
          module: "navLeft.kqgl",
          title:'navLeft.sgqd'
        }
      },
      {
        path: '/timetracking',
        name: 'Timetracking',
        component: resolve => require(['@/views/workattendance/timetracking'], resolve),
        meta: {
          module: "navLeft.kqgl",
          title:'navLeft.kqcl'
        }
      },
      {
        path: '/perinformation',
        name: 'Perinformation',
        component: resolve => require(['@/views/staffmanag/perinformation'], resolve),
        meta: {
          module: "navLeft.yggl",
          title:"navLeft.ryzl"
        }
      }
      ,
      {
        path: '/selfaudit',
        name: 'Selfaudit',
        component: resolve => require(['@/views/staffmanag/selfaudit'], resolve),
        meta: {
          module: "navLeft.yggl",
          title:"navLeft.zzsh"
        }
      },
      {
        path: '/authmanagem',
        name: 'Authmanagem',
        component: resolve => require(['@/views/staffmanag/authmanagem'], resolve),
        meta: {
          module: "navLeft.yggl",
          title:"navLeft.qxgl"
        }
      },

      // {
      //   path: '/timanage',
      //   name: 'Timanage',
      //   component: resolve => require(['@/views/staffmanag/timanage'], resolve),
      //   meta: {
      //     module: "navLeft.yggl",
      //     title:"navLeft.sjgl"
      //   }
      // },
      {
        path: '/synchro',
        name: 'Synchro',
        component: resolve => require(['@/views/staffmanag/synchro'], resolve),
        meta: {
          module: "navLeft.sjtb",
          title:"navLeft.qxgl"
        }
      },
      {
        path: '/replacement',
        name: 'Replacement',
        component: resolve => require(['@/views/staffmanag/replacement'], resolve),
        meta: {
          module: "navLeft",
        }
      },
      {
        path: '/perecord',
        name: 'Perecord',
        component: resolve => require(['@/views/reportdata/perecord'], resolve),
        meta: {
          module: "navLeft.bbsj",
          title:"navLeft.txjl"
        }
      },
      {
        path: '/attendance',
        name: 'Attendance',
        component: resolve => require(['@/views/reportdata/attendance'], resolve),
        meta: {
          module: "navLeft.bbsj",
          title:"navLeft.kqrb"
        }
      },
      {
        path: '/monthlyreport',
        name: 'Monthlyreport',
        component: resolve => require(['@/views/reportdata/monthlyreport'], resolve),
        meta: {
          module: "navLeft.bbsj",
          title:"navLeft.kqyb"
        }
      },
      {
        path: '/record',
        name: 'Record',
        component: resolve => require(['@/views/reportdata/record'], resolve),
        meta: {
          module: "navLeft.bbsj",
          title:"navLeft.fkjl"
        }
      },
      {
        path: '/illegal',
        name: 'Illegal',
        component: resolve => require(['@/views/reportdata/illegal'], resolve),
        meta: {
          module: "navLeft.bbsj",
          title:"navLeft.ffjl"
        }
      }
      ,
      {
        path: '/camerecord',
        name: 'Camerecord',
        component: resolve => require(['@/views/reportdata/camerecord'], resolve),
        meta: {
          module: "navLeft.bbsj",
          title:"navLeft.zpjl"
        }
      },
      {
        path: '/administrator',
        name: 'Administrator',
        component: resolve => require(['@/views/sysettings/administrator'], resolve),
        meta: {
          module: "navLeft.xtsz",
          title:"navLeft.glysz"
        }
      }
      ,
			{
			  path: '/superAdmin',
			  name: 'superAdmin',
			  component: resolve => require(['@/views/sysettings/superAdmin'], resolve),
			  meta: {
			    module: "系统设置",
			    title:"超级管理员"
			  }
			}
			,
      {
        path: '/traffic',
        name: 'Traffic',
        component: resolve => require(['@/views/sysettings/traffic'], resolve),
        meta: {
          module: "navLeft.xtsz",
          title:"navLeft.llksz"
        }
      },

      {
        path: '/recordproc',
        name: 'recordproc',
        component: resolve => require(['@/views/Intelligent/recordproc'], resolve),
        meta: {
          module: "智能IPC",
          title:"报警记录处理"
        }
      },
      {
        path: '/alarm',
        name: 'alarm',
        component: resolve => require(['@/views/Intelligent/alarm'], resolve),
        meta: {
          module: "智能IPC",
          title:"报警处理记录"
        }
      },
      {
        path: '/alarmpush',
        name: 'alarmpush',
        component: resolve => require(['@/views/Intelligent/alarmpush'], resolve),
        meta: {
          module: "智能IPC",
          title:"设置报警推送"
        }
      },
      {
        path: '/pusalrec',
        name: 'pusalrec',
        component: resolve => require(['@/views/Intelligent/pusalrec'], resolve),
        meta: {
          module: "智能IPC",
          title:"推送报警记录"
        }
      },
      {
        path: '/reatimevid',
        name: 'reatimevid',
        component: resolve => require(['@/views/Intelligent/reatimevid'], resolve),
        meta: {
          module: "智能IPC",
          title:"实时视频查看"
        }
      },
      {
        path: '/videorecord',
        name: 'reatimevid',
        component: resolve => require(['@/views/Intelligent/videorecord'], resolve),
        meta: {
          module: "智能IPC",
          title:"视频查看记录"
        }
      },

      {
        path: '/reporting',
        name: 'reporting',
        component: resolve => require(['@/views/Meal/reporting'], resolve),
        meta: {
          module: "用餐管理",
          title:"报餐管理"
        }
      },
      
      {
        path: '/mealrecord',
        name: 'mealrecord',
        component: resolve => require(['@/views/Meal/mealrecord'], resolve),
        meta: {
          module: "用餐管理",
          title:"用餐记录"
        }
      },
      {
        path: '/mealstas',
        name: 'mealstas',
        component: resolve => require(['@/views/Meal/mealstas'], resolve),
        meta: {
          module: "用餐管理",
          title:"用餐统计"
        }
      },
      {
        path: '/tableware',
        name: 'tableware',
        component: resolve => require(['@/views/Meal/tableware'], resolve),
        meta: {
          module: "用餐管理",
          title:"餐具管理"
        }
      },
      {
        path: '/mealdecl',
        name: 'mealdecl',
        component: resolve => require(['@/views/Meal/mealdecl'], resolve),
        meta: {
          module: "用餐管理",
          title:"报餐结算"
        }
      },
      {
        path: '/realtime',
        name: 'realtime',
        component: resolve => require(['@/views/space/realtime'], resolve),
        meta: {
          module: "智能车位",
          title:"实时车位"
        }
      },
      {
        path: '/parking',
        name: 'parking',
        component: resolve => require(['@/views/space/parking'], resolve),
        meta: {
          module: "智能车位",
          title:"车位设备"
        }
      },
      {
        path: '/carmang',
        name: 'carmang',
        component: resolve => require(['@/views/space/carmang'], resolve),
        meta: {
          module: "智能车位",
          title:"车位管理"
        }
      },
      {
        path: '/parkingdata',
        name: 'parkingdata',
        component: resolve => require(['@/views/space/parkingdata'], resolve),
        meta: {
          module: "智能车位",
          title:"停车数据"
        }
      },
      {
        path: '/pedestrian',
        name: 'pedestrian',
        component: resolve => require(['@/views/space/pedestrian'], resolve),
        meta: {
          module: "智能车位",
          title:"人车信息"
        }
      },
      {
        path: '/searecar',
        name: 'searecar',
        component: resolve => require(['@/views/space/searecar'], resolve),
        meta: {
          module: "智能车位",
          title:"寻车记录"
        }
      },
      {
        path: '/parkingphotos',
        name: 'parkingphotos',
        component: resolve => require(['@/views/space/parkingphotos'], resolve),
        meta: {
          module: "智能车位",
          title:"停车照片"
        }
      },
      {
        path: '/spacemark',
        name: 'spacemark',
        component: resolve => require(['@/views/space/spacemark'], resolve),
        meta: {
          module: "智能车位",
          title:"车位标注"
        }
      },
      {
        path: '/parkininfo',
        name: 'parkininfo',
        component: resolve => require(['@/views/space/parkininfo'], resolve),
        meta: {
          module: "智能车位",
          title:"车场信息"
        }
      },
         
{
  path: '/ces',
  name: 'Traffic',
  component: resolve => require(['@/views/sysettings/ces'], resolve),
  meta: {
    module: "navLeft.xtsz",
    title:"navLeft.llksz"
  }
}
      
    ]
  }

]

const router = new VueRouter({
  routes
})
// router.afterEach(()=>{
//   let globalLoding = document.getElementById('global-loding')
//   if(globalLoding){
//       globalLoding.style.display="none"
//   }
//   // next();
// })
// router.beforeEach((to, from, next) => {
//   let globalLoding = document.getElementById('global-loding')
  
//   if(!globalLoding){
//       globalLoding = document.createElement('div')
//       globalLoding.id = 'global-loding'
//       document.body.append(globalLoding)  
//   }else{
//     globalLoding.style.display="block"
//   }

//   if (to.meta.title) {
//     document.title =  to.meta.title
//   }else{
//     document.title =  to.meta.module
//   }
//   setTimeout(() => {
//     next()
//   }, 1000);
//   const user = JSON.parse(window.localStorage.getItem('user'))

//   // 校验非登录页面的登录状态
//   if (to.path !== '/login') {
//     if (user) {
//       // 已登录，允许通过
//       next()
//     } else {
//       // 没有登录，跳转到登录页面
//       next('/login')
//     }
//   } else {
//     // 登录页面，正常允许通过
//     next()
//   }
// })

router.beforeEach((to,from,next)=>{
  let globalLoding = document.getElementById('global-loding')
  
  if(!globalLoding){
      globalLoding = document.createElement('div')
      globalLoding.id = 'global-loding'
      document.body.append(globalLoding)  
  }else{
    globalLoding.style.display="block"
  }
  
  if (to.meta.title) {
      document.title = '悠行科技门禁管理平台'//+ to.meta.title
    }else if(to.meta.module){
      document.title = '悠行科技门禁管理平台'//+ to.meta.module
    }else{
      document.title="悠行科技门禁管理平台"
    }
    
  
  const user = JSON.parse(window.localStorage.getItem('user'))

  // 校验非登录页面的登录状态
  if (to.path !== '/login') {
    if (user) {
      // 已登录，允许通过
      setTimeout(() => {
        next()
      }, 100);
    } else {
      // 没有登录，跳转到登录页面
      next('/login')
    }
  } else {
    // 登录页面，正常允许通过
    setTimeout(() => {
      next()
    }, 300);
  }
})

router.afterEach(()=>{
  let globalLoding = document.getElementById('global-loding')
  if(globalLoding){
      globalLoding.style.display="none"
  }
  // next();
})

export default router
